.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*Animations*/
.noblink {
  -webkit-animation-iteration-count: 0 !important;
  animation-iteration-count: 0 !important;
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.flip {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  filter: fliph;
  /*IE*/
}
.clickable {
  cursor: pointer;
}
.notclickable {
  text-decoration: none !important;
  cursor: default !important;
}
.unlink {
  text-decoration: none !important;
}
.grid > * {
  width: 20%;
  display: inline-block;
}
@media screen and (max-width: 1600px) {
  .grid > * {
    width: 25%;
  }
}
@media screen and (max-width: 1250px) {
  .grid > * {
    width: 33%;
  }
}
@media screen and (max-width: 950px) {
  .grid > * {
    width: 50%;
  }
}
.giantText {
  font-size: 1.8rem;
}
.biggerText {
  font-size: 1.6rem;
}
.bigText {
  font-size: 1.4rem;
}
.mediumText {
  font-size: 1.2rem;
}
.normalText {
  font-size: 1.1rem;
}
.semiSmallText {
  font-size: 0.95rem;
}
.smallText {
  font-size: 0.9rem;
}
.smallerText {
  font-size: 0.8rem;
}
.tinyText {
  font-size: 0.75rem;
}
.smallestText {
  font-size: 0.65rem;
}
.ranks-modal #ranks {
  padding: 0;
  margin: 0px;
  font-size: 1.2rem;
}
.ranks-modal #ranks li {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  border-bottom: 1px solid #f2f2f2;
}
.ranks-modal #ranks li .score {
  color: #2196F3;
  font-weight: bold;
}
.ranks-modal #ranks li .userRank {
  font-weight: bold;
  color: #f32196;
}
