@staticBaseUrl: "https://cdn.beatsense.com/public";
@font-face {
  font-family: 'Lobster';
  src: url('/static/fonts/lobster-regular-webfont.eot');
  src: url('/static/fonts/lobster-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/lobster-regular-webfont.woff2') format('woff2'),
  url('/static/fonts/lobster-regular-webfont.woff') format('woff'),
  url('/static/fonts/lobster-regular-webfont.ttf') format('truetype'),
  url('/static/fonts/lobster-regular-webfont.svg#lobsterregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: 'ThirstyScript';
//  src: url('/static/fonts/ThirstyScript/ThirstyScriptRegular.otf') format('opentype');
//}
//
//@font-face {
//  font-family: 'ThirstyScript';
//  src: url('/static/fonts/ThirstyScript/ThirstyRoughLight.otf') format('opentype');
//  font-weight: normal;
//}

@font-face {
  font-family: 'OleoScript';
  src: url('/static/fonts/OleoScript/OleoScript-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'OleoScript';
  src: url('/static/fonts/OleoScript/OleoScript-Regular.ttf') format('truetype');
  font-weight: normal;
}

//@font-face {
//  font-family: 'Open Sans';
//  src: url('/static/fonts/Open_Sans/OpenSans-Semibold.woff') format('woff'), url('/static/fonts/Open_Sans/OpenSans-Semibold.ttf') format('truetype');
//  font-weight: 100;
//}
//@font-face {
//  font-family: 'Open Sans';
//  src: url('/static/fonts/Open_Sans/OpenSans-Light.woff') format('woff'), url('/static/fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Open Sans';
//  src: url('/static/fonts/Open_Sans/OpenSans-Regular.woff') format('woff'), url('/static/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
//  font-weight: bold;
//}
//
//@font-face {
//  font-family: 'Open Sans';
//  src: url('/static/fonts/Open_Sans/OpenSans-Bold.woff') format('woff'), url('/static/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
//  font-weight: 200;
//}

@font-face {
  font-family: 'Poppins';
  src: url('/static/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Poppins';
  src: url('/static/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Poppins';
  src: url('/static/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Poppins';
  src: url('/static/fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 300;
}
//must be last
@font-face {
  font-family: 'Poppins';
  src: url('/static/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}