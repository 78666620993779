@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";

//Sticky song modal

.sticky-song-modal {
    .modal-content{
        min-width: 500px !important;
    }
    .user-link {
        color: blue;
    }
    .fa-compact-disc {
        font-size: 50px;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    h3 {
        font-weight: bold;
        padding: 10px;
    }
    button {
        background-color: @color0_transparent;
        border: none;
        position: absolute;
        top: 55px;
        right: 20px;
        padding: 5px 7px 6px 10px;
        border-radius: 0x;
    }
    .modal-content {
        //width: 506px;
    }
    .player-holder {
        margin-top: 20px;
    }
}