@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";

#roomThemesManagement{

    position: relative;
    overflow-y: hidden;
    max-height: 90vh;
    min-height: 400px;

    h4{
        font-weight: bold;
        text-align: center;
    }
    .btn-return{
        position: absolute;
        top: 0;
        right: 27px;
    }

    .themesHolder{

        .searchBox{
            margin: 10px 0;
            position: relative;
            display: flex;
            align-items: center;
            > div{
                .flex;
                label{
                    position: relative;
                    top: 2px;
                    .semiSmallText;
                    font-weight: bold;
                    margin-right: 5px;
                }
            }
            .searchBox_input{
                position: relative;
                > input[type="text"]{
                    width: 416px;
                    height: 30px;
                    margin-right: 8px;
                }
                > .search-icon{
                    right: 14px;
                    position: absolute;
                    top: 9px;
                    color: @grayText;
                }
            }

            > input[type=checkbox]:nth-child(0){
                margin-left: 10px;
            }

            > label{
                .smallText;
                margin-right: 8px;
            }
        }

        #freeVideoTheme{
            .smallText();
            color: @color2;
            margin: 0px 0px 9px;
            font-weight: bold;
        }

        &.my{
            border-bottom: @border;
            margin-bottom: 12px;
            padding-bottom: 9px;
        }
        h2{
            margin: 0 0 10px;
            font-weight: bold;
        }
        .listHolder{
            overflow-y: auto;
            max-height: 660px;
            display: flex;
            flex-wrap: wrap;
            .dummyScrollBar{
                position: absolute;
                right: 1px;
                height: 100%;
                width: 14px;
                background-color: @bgColor;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                i{
                    .smallText;
                    transform: rotate(90deg);
                    color: @bgColor2;
                }
            }
            .theme{
                text-align: center;
                cursor: pointer;
                padding: 0px;
                margin: 2px;
                position: relative;

                .themeImage{
                    cursor: pointer;
                    text-align: center;
                    height: 99px; //to fix over height with videos
                    > * {
                        width: 176px;
                        height: 99px;
                    }

                    > video{
                        padding: 0;
                        margin: 0;
                        border: 1px solid gray;
                        background-color: black;
                    }
                }

                &:nth-of-type (3n){
                    .themeImage {
                        text-align: left;
                    }
                }

                .themeType{
                    position: absolute;
                    bottom: 23px;
                    right: 10px;
                    color: white;
                    .smallText;
                }

                .themeName{
                    .smallText;
                    text-align: center;
                    margin-top: 2px;
                }

                .selectedThemeLayer{
                    background-color: rgba(255,255,255,0.6);
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    width: 100%;
                    height: 86%;
                    .flex();
                }
                &.active{

                }

                .selectButtonHolder{
                    text-align: center;
                }

                .lockedTheme, .btnUnlockTheme{
                    display: none;
                }

                .btnUnlockTheme{
                    .beat{

                        height: 16px;
                        bottom: 1px;
                        position: relative;
                        right: 2px;
                    }
                    &.notEnoughBeats{
                        background-color: gray !important;
                    }
                }

                .lockedTheme{
                    top: 7px;
                    position: absolute;
                    z-index: 1000000;
                    right: 10px;
                    color: gold;
                    opacity: 0.8;
                }

                &.locked{
                    .lockedTheme, .btnUnlockTheme{
                        display: block;
                    }
                    .btnSelectTheme{
                        display: none;
                    }
                }
            }
        }
    }

    #mySpinner{
        position: absolute;
        top: 200px;
        left: 50%;
    }
}
