@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";

#topMenu {
    margin-top: 35px;
    .opener{
        cursor: pointer;
    }
    @-moz-document url-prefix() {
        .opener {
            position: relative;
            top:  1px;
        }
    }

    .menu{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item{
            opacity: 0.9;
            font-size: 0.7em;
            color: white;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            .flex;

            &:hover{
                box-shadow: 0px 0px 7px white;
            }
            img{
                width: 40px;
                height: 40px;
            }

            &#sense{
                background-color: rgba(28, 163, 171, 0.7);
            }
            &#about{
                background-color: @color1;
            }
            &#faq{
                background-color: #DF5D69;
            }
            &#blog{
                background-color: @green4;
            }
        }
    }
}