#update-invalid-email-modal.my-modal {
  width: 100%;
  display: table;
  margin-bottom: 15px;
}
#update-invalid-email-modal.my-modal .modal-header {
  padding-bottom: 3px;
}
#update-invalid-email-modal.my-modal .modal-header h2 {
  font-weight: bold;
  text-align: center;
}
#update-invalid-email-modal.my-modal label {
  margin-bottom: 5px;
}
#update-invalid-email-modal.my-modal button {
  font-weight: bold;
}
#update-invalid-email-modal.my-modal input {
  float: left;
  width: 70%;
  margin-right: 10px;
}
