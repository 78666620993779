#roomThemesManagement {
  position: relative;
  overflow-y: hidden;
  max-height: 90vh;
  min-height: 400px;
}
#roomThemesManagement h4 {
  font-weight: bold;
  text-align: center;
}
#roomThemesManagement .btn-return {
  position: absolute;
  top: 0;
  right: 27px;
}
#roomThemesManagement .themesHolder .searchBox {
  margin: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
}
#roomThemesManagement .themesHolder .searchBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#roomThemesManagement .themesHolder .searchBox > div label {
  position: relative;
  top: 2px;
  font-size: 0.95rem;
  font-weight: bold;
  margin-right: 5px;
}
#roomThemesManagement .themesHolder .searchBox .searchBox_input {
  position: relative;
}
#roomThemesManagement .themesHolder .searchBox .searchBox_input > input[type="text"] {
  width: 416px;
  height: 30px;
  margin-right: 8px;
}
#roomThemesManagement .themesHolder .searchBox .searchBox_input > .search-icon {
  right: 14px;
  position: absolute;
  top: 9px;
  color: #6f6f6f;
}
#roomThemesManagement .themesHolder .searchBox > input[type=checkbox]:nth-child(0) {
  margin-left: 10px;
}
#roomThemesManagement .themesHolder .searchBox > label {
  font-size: 0.9rem;
  margin-right: 8px;
}
#roomThemesManagement .themesHolder #freeVideoTheme {
  font-size: 0.9rem;
  color: #f32196;
  margin: 0px 0px 9px;
  font-weight: bold;
}
#roomThemesManagement .themesHolder.my {
  border-bottom: 1px solid #D2D2D2;
  margin-bottom: 12px;
  padding-bottom: 9px;
}
#roomThemesManagement .themesHolder h2 {
  margin: 0 0 10px;
  font-weight: bold;
}
#roomThemesManagement .themesHolder .listHolder {
  overflow-y: auto;
  max-height: 660px;
  display: flex;
  flex-wrap: wrap;
}
#roomThemesManagement .themesHolder .listHolder .dummyScrollBar {
  position: absolute;
  right: 1px;
  height: 100%;
  width: 14px;
  background-color: #e6e6e6;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
#roomThemesManagement .themesHolder .listHolder .dummyScrollBar i {
  font-size: 0.9rem;
  transform: rotate(90deg);
  color: rgba(0, 0, 0, 0.5);
}
#roomThemesManagement .themesHolder .listHolder .theme {
  text-align: center;
  cursor: pointer;
  padding: 0px;
  margin: 2px;
  position: relative;
}
#roomThemesManagement .themesHolder .listHolder .theme .themeImage {
  cursor: pointer;
  text-align: center;
  height: 99px;
}
#roomThemesManagement .themesHolder .listHolder .theme .themeImage > * {
  width: 176px;
  height: 99px;
}
#roomThemesManagement .themesHolder .listHolder .theme .themeImage > video {
  padding: 0;
  margin: 0;
  border: 1px solid gray;
  background-color: black;
}
#roomThemesManagement .themesHolder .listHolder .theme:nth-of-type (3n) .themeImage {
  text-align: left;
}
#roomThemesManagement .themesHolder .listHolder .theme .themeType {
  position: absolute;
  bottom: 23px;
  right: 10px;
  color: white;
  font-size: 0.9rem;
}
#roomThemesManagement .themesHolder .listHolder .theme .themeName {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 2px;
}
#roomThemesManagement .themesHolder .listHolder .theme .selectedThemeLayer {
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 86%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#roomThemesManagement .themesHolder .listHolder .theme .selectButtonHolder {
  text-align: center;
}
#roomThemesManagement .themesHolder .listHolder .theme .lockedTheme,
#roomThemesManagement .themesHolder .listHolder .theme .btnUnlockTheme {
  display: none;
}
#roomThemesManagement .themesHolder .listHolder .theme .btnUnlockTheme .beat {
  height: 16px;
  bottom: 1px;
  position: relative;
  right: 2px;
}
#roomThemesManagement .themesHolder .listHolder .theme .btnUnlockTheme.notEnoughBeats {
  background-color: gray !important;
}
#roomThemesManagement .themesHolder .listHolder .theme .lockedTheme {
  top: 7px;
  position: absolute;
  z-index: 1000000;
  right: 10px;
  color: gold;
  opacity: 0.8;
}
#roomThemesManagement .themesHolder .listHolder .theme.locked .lockedTheme,
#roomThemesManagement .themesHolder .listHolder .theme.locked .btnUnlockTheme {
  display: block;
}
#roomThemesManagement .themesHolder .listHolder .theme.locked .btnSelectTheme {
  display: none;
}
#roomThemesManagement #mySpinner {
  position: absolute;
  top: 200px;
  left: 50%;
}
