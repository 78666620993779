#buddiesListHolder {
  position: relative;
}
#buddiesListHolder .opener i {
  margin: 0 2px;
  bottom: 1px;
  position: relative;
}
#buddiesListHolder .listHolder {
  position: fixed;
  top: 120px;
  margin-top: -12px;
  right: 0px;
  text-align: left;
  max-height: 329px;
}
#buddiesListHolder .listHolder .list .buddy {
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 8px;
  margin-bottom: 5px;
}
#buddiesListHolder .listHolder .list .buddy img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
#buddiesListHolder .listHolder .list .buddy img.online {
  border: 3px solid #39e737;
}
#buddiesListHolder .listHolder .list .buddy a {
  max-width: 90px;
  text-overflow: ellipsis;
}
#buddiesListHolder .listHolder .list .buddy a:hover {
  font-weight: 100;
}
#buddiesListHolder .listHolder .list .buddy .userName {
  margin-left: 5px;
}
#buddiesListHolder .listHolder .list .buddy .btnRemove {
  display: none;
  position: absolute;
  height: 100%;
  color: #2196F3;
  left: 0px;
  top: -3px;
  font-size: 0.9rem;
  z-index: 2;
}
#buddiesListHolder .listHolder .list .buddy:hover .btnRemove {
  display: block;
}
#buddiesListHolder .listHolder .listEmpty {
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
  width: 150px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #404040;
  font-size: 0.9rem;
  white-space: normal;
}
