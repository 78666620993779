#topMenu {
  margin-top: 35px;
}
#topMenu .opener {
  cursor: pointer;
}
@-moz-document url-prefix() {
  #topMenu .opener {
    position: relative;
    top: 1px;
  }
}
#topMenu .menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#topMenu .menu .item {
  opacity: 0.9;
  font-size: 0.7em;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#topMenu .menu .item:hover {
  box-shadow: 0px 0px 7px white;
}
#topMenu .menu .item img {
  width: 40px;
  height: 40px;
}
#topMenu .menu .item#sense {
  background-color: rgba(28, 163, 171, 0.7);
}
#topMenu .menu .item#about {
  background-color: #3D3C49;
}
#topMenu .menu .item#faq {
  background-color: #DF5D69;
}
#topMenu .menu .item#blog {
  background-color: #55DA96;
}
