@import (reference) 'variables';

.beatsModal {
	.modal-header {
		padding: 12px 0 7px;
	}
	.modal-body {
		padding: 0px 25px 0px;
		> h4 {
			font-weight: normal;
			margin-bottom: 13px;
			b{
				font-weight: 100;
			}
		}
		.beats{
			height: 20px;
		}
	}
	ul {
		list-style-type: initial;
		margin-top: 5px;
		padding-left: 10px;
		max-height: 75vh;
		.beatsFeature {
			margin-bottom: 15px;
		}

		h4 {
			font-weight: bold;
			margin-bottom: 2px;
			.mediumText;
		}
	}
}

#my-beats-modal{
	.modal-body{
		text-align: center;
		b{
			font-weight: 100;
		}
		#beatsBalanceLine{
			padding: 0px 10px 8px;
			margin-bottom: 5px;
		}
		img{
			height: 22px !important;
			vertical-align: sub;
			bottom: 2px;
			position: relative;
			margin-left: 2px;
		}
		#beatsExplanationLink{
			margin-top: 20px;
			margin-bottom: 3px;
			text-decoration: underline;
			font-weight: bold;
		}
	}
}

#getMoreBeats:hover {
	text-decoration: none;
}

#beatsPurchaseModal {
	h4 {
		text-align: center;
		padding-bottom: 5px;
		margin: 0px 0 14px;
		font-weight: normal;
		img{
			height: 20px;
			position: relative;
			bottom: 1px;
			left: 1px;
		}
		b{
			font-weight: 100;
		}
	}
	.modal-body {
		.transferMsg {
			margin-top: 10px;
		}
	}

	.package {
		#freePackage{
			ul li:not(:last-child){
				margin-bottom: 15px;
				margin-top: 5px;
			}
			a{
				color: blue;
			}
		}
		td {
			padding: 0 8px 14px 0;
			.mediumText;
		}
		.packageName {
			font-weight: bold;
			&.free {
				font-weight: 100;
				.bigText;
			}
		}

		.getIt {
			border: 0 none;
			border-radius: @borderRadius;
			margin-top: 2px;
			padding: 3px 13px 6px;
			margin-left: 6px;
			background-color: @green4;
			color: white;
			font-size: 1rem;

			&:hover {
				background-color: #49BB80 !important;
				text-decoration: none;
			}
		}

		#affiliateLinkWrapper {
			padding: 3px 10px 4px;
			margin-top: 4px;
			margin-bottom: 5px;
			overflow-x: hidden;
			background-color: white;
			width: 435px;
			.affiliateLink {
				white-space: nowrap;
				color: black;
				i{
					color: @color0;
				}
				&:hover {
					text-decoration: none;
				}
			}
			.affiliateLinkInput {
				margin-top: 2px;
				width: 100%;
				border: none;
			}
		}
	}
	#beatsForFree{
		background-color: @bgColor;
		padding: 5px 14px 15px;
	}
	#beatsExplanationLink{
		text-decoration: underline;
		margin-top: 5px;
		color: @color2;
	}
}

#beatsPurchaseSuccess {
	text-align: center;
	font-weight: bold;
	margin-bottom: 2px;
	.modal-header {
		margin-bottom: 12px;
	}
	.balance {
		font-weight: 200;
	}
	button {
		border: 0 none;
		border-radius: 35px;
		font-weight: 100;
		margin-top: 10px;
		padding: 3px 11px 5px;
		margin-left: 6px;
	}
}

#beatsPurchaseFailure {
	text-align: center;
	.modal-header {
		margin-bottom: 6px;
	}
	.retryHolder {
		button {
			border: 0 none;
			border-radius: 50px;
			font-weight: 100;
			margin-top: 10px;
			padding: 3px 16px 5px;
			color: #ffffff;
			margin-bottom: 6px;
		}
	}
}

#beatsInsufficient {
	text-align: center;
	.modal-header {
		margin-bottom: 6px;
	}
	button {
		border: 0 none;
		border-radius: 50px;
		padding: 8px 20px 10px;
		color: #ffffff;
		margin-bottom: 10px;
	}
	a {
		.smallText;
		font-weight: bold;
		text-decoration: underline;
	}
	.modal-body {
		h4 {
			margin: 20px 0px 12px;
		}
	}
}

#enableFeaturePopup{
	text-align: center;
	.modal-body {

	}
}