@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";
@import (reference) "general";

#buddiesListHolder{
    position: relative;
    .opener{
        i{
            margin: 0 2px;
            bottom: 1px;
            position: relative;
        }
    }

    .listHolder{
        position: fixed;
        top: 120px;
        margin-top: -12px;
        right: 0px;
        text-align: left;
        max-height: @playerHeight;

        .list{
            .buddy{
                cursor: pointer;
                .smallText;
                display: flex;
                align-items: center;
                position: relative;
                padding: 0 8px;
                margin-bottom: 5px;
                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    &.online{
                        border: 3px solid @onlineGreen;
                    }
                }
                a{
                    max-width: 90px;
                    text-overflow: ellipsis;
                    &:hover{
                        font-weight: 100;
                    }
                }
                .userName{
                    margin-left: 5px;
                }
                .btnRemove{
                    display: none;
                    position: absolute;
                    height: 100%;
                    color: @color0;
                    left: 0px;
                    top: -3px;
                    .smallText;
                    z-index: 2;
                }

                &:hover{
                    .btnRemove{
                        display: block;
                    }
                }
            }
        }

        .listEmpty{
            .box;
            width: 150px;
            background-color: @whiteBg_transparent;
            color: @textColor;
            .smallText;
            white-space: normal;
        }
    }
}