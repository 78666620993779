@staticBaseUrl: "https://cdn.beatsense.com/public";
@import 'variables';

.ranks-modal{
  #ranks{
    padding: 0;
    margin: 0px;
    .mediumText;
    li{
      display: flex;
      justify-content: space-between;
      padding: 3px 0;
      border-bottom: 1px solid #f2f2f2;
      .score{
        color: @color0;
        font-weight: bold;
      }
      .userRank{
        font-weight: bold;
        color: @color2
      }
    }
  }
}