.beatsModal .modal-header {
  padding: 12px 0 7px;
}
.beatsModal .modal-body {
  padding: 0px 25px 0px;
}
.beatsModal .modal-body > h4 {
  font-weight: normal;
  margin-bottom: 13px;
}
.beatsModal .modal-body > h4 b {
  font-weight: 100;
}
.beatsModal .modal-body .beats {
  height: 20px;
}
.beatsModal ul {
  list-style-type: initial;
  margin-top: 5px;
  padding-left: 10px;
  max-height: 75vh;
}
.beatsModal ul .beatsFeature {
  margin-bottom: 15px;
}
.beatsModal ul h4 {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 1.2rem;
}
#my-beats-modal .modal-body {
  text-align: center;
}
#my-beats-modal .modal-body b {
  font-weight: 100;
}
#my-beats-modal .modal-body #beatsBalanceLine {
  padding: 0px 10px 8px;
  margin-bottom: 5px;
}
#my-beats-modal .modal-body img {
  height: 22px !important;
  vertical-align: sub;
  bottom: 2px;
  position: relative;
  margin-left: 2px;
}
#my-beats-modal .modal-body #beatsExplanationLink {
  margin-top: 20px;
  margin-bottom: 3px;
  text-decoration: underline;
  font-weight: bold;
}
#getMoreBeats:hover {
  text-decoration: none;
}
#beatsPurchaseModal h4 {
  text-align: center;
  padding-bottom: 5px;
  margin: 0px 0 14px;
  font-weight: normal;
}
#beatsPurchaseModal h4 img {
  height: 20px;
  position: relative;
  bottom: 1px;
  left: 1px;
}
#beatsPurchaseModal h4 b {
  font-weight: 100;
}
#beatsPurchaseModal .modal-body .transferMsg {
  margin-top: 10px;
}
#beatsPurchaseModal .package #freePackage ul li:not(:last-child) {
  margin-bottom: 15px;
  margin-top: 5px;
}
#beatsPurchaseModal .package #freePackage a {
  color: blue;
}
#beatsPurchaseModal .package td {
  padding: 0 8px 14px 0;
  font-size: 1.2rem;
}
#beatsPurchaseModal .package .packageName {
  font-weight: bold;
}
#beatsPurchaseModal .package .packageName.free {
  font-weight: 100;
  font-size: 1.4rem;
}
#beatsPurchaseModal .package .getIt {
  border: 0 none;
  border-radius: 20px;
  margin-top: 2px;
  padding: 3px 13px 6px;
  margin-left: 6px;
  background-color: #55DA96;
  color: white;
  font-size: 1rem;
}
#beatsPurchaseModal .package .getIt:hover {
  background-color: #49BB80 !important;
  text-decoration: none;
}
#beatsPurchaseModal .package #affiliateLinkWrapper {
  padding: 3px 10px 4px;
  margin-top: 4px;
  margin-bottom: 5px;
  overflow-x: hidden;
  background-color: white;
  width: 435px;
}
#beatsPurchaseModal .package #affiliateLinkWrapper .affiliateLink {
  white-space: nowrap;
  color: black;
}
#beatsPurchaseModal .package #affiliateLinkWrapper .affiliateLink i {
  color: #2196F3;
}
#beatsPurchaseModal .package #affiliateLinkWrapper .affiliateLink:hover {
  text-decoration: none;
}
#beatsPurchaseModal .package #affiliateLinkWrapper .affiliateLinkInput {
  margin-top: 2px;
  width: 100%;
  border: none;
}
#beatsPurchaseModal #beatsForFree {
  background-color: #e6e6e6;
  padding: 5px 14px 15px;
}
#beatsPurchaseModal #beatsExplanationLink {
  text-decoration: underline;
  margin-top: 5px;
  color: #f32196;
}
#beatsPurchaseSuccess {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2px;
}
#beatsPurchaseSuccess .modal-header {
  margin-bottom: 12px;
}
#beatsPurchaseSuccess .balance {
  font-weight: 200;
}
#beatsPurchaseSuccess button {
  border: 0 none;
  border-radius: 35px;
  font-weight: 100;
  margin-top: 10px;
  padding: 3px 11px 5px;
  margin-left: 6px;
}
#beatsPurchaseFailure {
  text-align: center;
}
#beatsPurchaseFailure .modal-header {
  margin-bottom: 6px;
}
#beatsPurchaseFailure .retryHolder button {
  border: 0 none;
  border-radius: 50px;
  font-weight: 100;
  margin-top: 10px;
  padding: 3px 16px 5px;
  color: #ffffff;
  margin-bottom: 6px;
}
#beatsInsufficient {
  text-align: center;
}
#beatsInsufficient .modal-header {
  margin-bottom: 6px;
}
#beatsInsufficient button {
  border: 0 none;
  border-radius: 50px;
  padding: 8px 20px 10px;
  color: #ffffff;
  margin-bottom: 10px;
}
#beatsInsufficient a {
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: underline;
}
#beatsInsufficient .modal-body h4 {
  margin: 20px 0px 12px;
}
#enableFeaturePopup {
  text-align: center;
}
html {
  font-size: 14px;
}
html body {
  font-family: 'Poppins', Calibri, sans-serif;
  color: #404040;
  transition-duration: 0.5s;
  -webkit-transition-property: background-color, border-color;
  transition-property: background-color, border-color;
  overflow-x: hidden;
  background-color: #f8f8f8;
  min-width: 1150px;
}
html body.modal-open {
  overflow: inherit;
  overflow-x: hidden;
}
html body h1 {
  font-size: 1.8rem;
  font-weight: bold;
}
html body h2 {
  font-size: 1.4rem;
  margin: 0px 0px 8px 0px;
}
html body h3 {
  font-size: 1.2rem;
  margin: 0px 0px 8px 0px;
}
html body h3,
html body h4,
html body h2 {
  font-weight: 100;
}
html body h6 {
  font-weight: bold;
}
html body input[type="radio"],
html body input[type="checkbox"] {
  margin: 0px 3px 0px 0px;
}
html body.innerPlayer {
  overflow: hidden;
}
.facebook {
  background-color: #4A66A0;
}
.twitter {
  background-color: #00aced;
}
.border {
  border: 1px solid #e8e8e8;
}
.box {
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
}
.fa-times {
  cursor: pointer;
}
ul {
  list-style-type: none;
  padding: 0;
}
.preloaderWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  font-size: 50px;
  padding: 20px 0px;
  color: #6f6f6f;
}
input[type='text'],
input[type='email'],
input[type='password'] {
  height: 28px;
  color: black;
  position: relative;
  padding: 1px 10px 3px;
  border: 1px solid #D2D2D2;
}
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}
a,
a:hover,
a:active,
a:focus {
  color: inherit;
}
.background-audio {
  display: none;
}
.underline {
  text-decoration: underline;
}
.fa-bolt {
  color: #eccc1b;
  margin-left: 3px;
}
.orange {
  color: #f32196;
}
.bold {
  font-weight: bold;
}
.bolder {
  font-weight: 100;
}
a:focus {
  text-decoration: none;
}
.white {
  color: white !important;
}
.red {
  color: #f32196;
}
.bigBtn {
  font-size: 1em !important;
  padding: 4px 20px;
}
/*Bootstrap fixes*/
.label-default {
  background: none;
}
.badge {
  background: none;
  padding: 0;
  margin: 0;
}
.btn-primary {
  border: none;
}
.btn,
button {
  -webkit-transitiobtn: background-color 0.35ws;
  -moz-transition: background-color 0.35s;
  -o-transition: background-color 0.35s;
  transition: background-color 0.35s;
  background-color: #f32196;
  color: white;
  border-radius: 20px;
  border: none;
  padding: 8px 13px 10px;
  outline: none;
}
.btn:hover,
button:hover,
.btn:active,
button:active,
.btn:focus,
button:focus {
  background-color: #d50c7c;
  color: white;
}
.btn .beatsWrapper,
button .beatsWrapper {
  font-size: 0.9rem;
  margin-left: 2px;
  color: yellow;
}
.btn .beatsWrapper img,
button .beatsWrapper img {
  height: 16px;
  bottom: 2px;
  margin-left: 1px;
  position: relative;
}
.btn:hover,
button:hover {
  background-color: #d50c7c;
}
.btn.disabled,
button.disabled {
  text-decoration: none !important;
  cursor: default !important;
  background-color: gray !important;
  background: gray !important;
}
.btn.secondary,
button.secondary {
  background-color: #2196F3 !important;
}
.btn.secondary:hover,
button.secondary:hover {
  background-color: #0c7cd5 !important;
}
:focus {
  outline: none !important;
}
.btn-default,
.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  color: #ffffff;
}
.login-modal .msgArea,
.register-modal .msgArea {
  text-align: center;
  margin-top: -13px;
  margin-bottom: 15px;
  color: #6f6f6f;
}
.login-modal .signupLoginArea,
.register-modal .signupLoginArea {
  max-width: 275px;
  margin: 0 auto;
}
.login-modal .signupLoginArea .error,
.register-modal .signupLoginArea .error {
  padding-left: 9px;
  padding-bottom: 2px;
  margin-bottom: 5px;
}
.login-modal .signupLoginArea input,
.register-modal .signupLoginArea input {
  height: 29px;
}
.login-modal .signupLoginArea .form-control,
.register-modal .signupLoginArea .form-control {
  margin-bottom: 7px;
}
.login-modal .signupLoginArea .form-control[type=text]::placeholder,
.register-modal .signupLoginArea .form-control[type=text]::placeholder,
.login-modal .signupLoginArea .form-control[type=password]::placeholder,
.register-modal .signupLoginArea .form-control[type=password]::placeholder {
  color: darkgray;
  font-size: 0.9rem;
}
.login-modal .signupLoginArea .buttons-holder,
.register-modal .signupLoginArea .buttons-holder {
  margin-top: 10px;
  margin-bottom: 11px;
}
.login-modal .signupLoginArea .buttons-holder button,
.register-modal .signupLoginArea .buttons-holder button {
  width: 100%;
  font-weight: normal;
}
.login-modal .signupLoginArea .buttons-holder #signUpLoginBtn,
.register-modal .signupLoginArea .buttons-holder #signUpLoginBtn {
  background-color: #2196F3;
  padding: 7px 5px 9px;
}
.login-modal .signupLoginArea .buttons-holder #signUpLoginBtn:hover,
.register-modal .signupLoginArea .buttons-holder #signUpLoginBtn:hover {
  background-color: #0c7cd5;
}
.login-modal .signupLoginArea .buttons-holder #signUpLoginBtn i,
.register-modal .signupLoginArea .buttons-holder #signUpLoginBtn i {
  top: 2px;
  position: relative;
}
.login-modal .signupLoginArea .additional-ops-link,
.register-modal .signupLoginArea .additional-ops-link {
  color: #f32196;
  font-size: 0.9rem;
  margin-top: 14px;
}
.login-modal .signupLoginArea #verificationCode input,
.register-modal .signupLoginArea #verificationCode input {
  margin: 6px 0;
}
.login-modal .signupLoginArea #verificationCode a,
.register-modal .signupLoginArea #verificationCode a {
  color: #2196F3;
}
.login-modal .signupLoginArea .approveTos,
.register-modal .signupLoginArea .approveTos {
  display: flex;
  color: gray;
}
.login-modal .signupLoginArea .approveTos a,
.register-modal .signupLoginArea .approveTos a {
  color: #0c7cd5;
  text-decoration: underline;
}
.login-modal .signupLoginArea .approveTos input,
.register-modal .signupLoginArea .approveTos input {
  height: auto;
}
.login-modal .signupLoginArea .approveTos label,
.register-modal .signupLoginArea .approveTos label {
  font-weight: normal !important;
  font-size: 1rem !important;
  margin-bottom: 0 !important;
}
.online {
  color: #39e737;
}
.login-modal .buttons-holder {
  text-align: center;
}
.login-modal .buttons-holder a {
  top: 3px;
}
.forgotPasswordModal .txtEmail {
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
  margin-top: 1px;
}
.forgotPasswordModal .buttons-holder {
  display: flex;
  justify-content: center;
}
.forgotPasswordModal .buttons-holder a {
  margin-left: 5px;
}
.form-group {
  margin: 0;
}
.form-control {
  margin-bottom: 6px;
  border-radius: 0;
  padding: 0 0 0 10px;
  box-shadow: none;
}
label {
  font-weight: normal;
  margin-bottom: 2px;
}
.modal-footer {
  text-align: left;
  margin-top: 10px;
}
/*End bootstrap fixes*/
input::-ms-clear {
  display: none;
}
div.tooltip-inner {
  text-align: left;
  white-space: pre-wrap !important;
  max-width: 500px;
  letter-spacing: 0.2px !important;
  background-color: #3D3C49;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #3D3C49;
}
.tooltip.top-left .tooltip-arrow {
  border-top-color: #3D3C49;
}
.tooltip.top-right .tooltip-arrow {
  border-top-color: #3D3C49;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #3D3C49;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #3D3C49;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #3D3C49;
}
.tooltip.bottom-left .tooltip-arrow {
  border-bottom-color: #3D3C49;
}
.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #3D3C49;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
}
a,
a:focus {
  outline: 0 !important;
}
.form-group.tags {
  display: table;
  width: 100%;
}
#main-error,
#main-notification {
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  position: fixed;
  z-index: 1051;
  font-weight: bold;
  line-height: 50px;
  padding: 3px 0 5px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: rgba(33, 150, 243, 0.8) !important;
  color: white;
  font-size: 1.4rem;
  cursor: pointer;
}
#main-error button,
#main-notification button {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 1.2rem;
  margin-left: 10px;
  background-color: #0c7cd5;
}
.logoFont,
.project-name {
  font-family: 'OleoScript', cursive;
}
.introjs-tooltip {
  min-width: 300px;
  max-width: inherit;
  padding: 10px 17px 20px;
}
.introjs-tooltip .introjs-tooltiptext h1 {
  margin: 8px 0px;
  font-size: 1.4rem;
}
.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.2);
}
.introjs-bullets ul li a {
  background: #3D3C49;
}
.introjs-bullets ul li a.active {
  background: #2196F3;
}
.introjs-tooltipbuttons a,
.introjs-disabled:focus,
.introjs-disabled,
.introjs-disabled:hover,
.introjs-button:focus,
.introjs-button:active {
  color: white;
  background-color: #3D3C49;
  background-image: none;
  border: none;
  font-weight: normal;
  text-shadow: none;
}
.introjs-disabled,
.introjs-disabled:hover {
  background-color: #bdbcc8;
}
.beats {
  display: inline-flex;
  align-items: center;
}
.beatsWrapper {
  margin-left: 2px;
  color: yellow;
}
.beatsWrapper img {
  height: 16px;
  bottom: 2px;
  margin-left: 1px;
  position: relative;
}
.main > .container {
  width: 1070px;
  position: relative;
}
.main.admin {
  margin-top: 50px;
}
.main.admin .table-bordered {
  border: 1px solid #3D3C49;
}
.main #mainBanner {
  background-image: url("https://cdn.beatsense.com/public/images/themes/beach.jpg");
}
.main #mainBanner > video {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}
.field-error {
  color: #f53c56;
  padding-bottom: 5px;
}
.nopadding {
  padding: 0 !important;
}
.nomargin {
  margin: 0 !important;
}
.link:hover {
  text-decoration: underline;
}
.clear {
  clear: both;
}
.lighter {
  font-weight: normal;
}
.modal {
  display: flex !important;
}
.modal .modal-dialog {
  margin: auto;
  width: auto;
}
.modal .modal-dialog .modal-content {
  min-width: 350px;
  max-width: 636px;
  border: none;
  background-color: inherit;
  border-radius: 20px;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 0px 20px 20px;
}
.modal .modal-dialog .modal-content .modal-body label {
  font-weight: bold;
  margin-bottom: 4px;
}
.modal .modal-dialog .modal-content .my-modal {
  position: relative;
  border-radius: 20px;
  background-color: white;
}
.modal .modal-dialog .modal-content .my-modal .small-link {
  font-size: 0.8rem;
  color: #6f6f6f;
  margin-top: 38px;
  position: relative;
  top: 3px;
}
.modal .modal-dialog .modal-content .my-modal .small-link:hover {
  text-decoration: underline;
}
.modal .modal-dialog .modal-content .my-modal .additional-ops-link {
  text-align: center;
}
.modal .modal-dialog .modal-content .my-modal .additional-ops-link a {
  text-decoration: underline;
  cursor: pointer;
}
.modal .modal-dialog .modal-content .my-modal form .buttons-holder {
  display: table;
  width: 100%;
  margin-top: 12px;
}
.modal .modal-dialog .modal-content .my-modal .modal-body > .state-message {
  line-height: 18px;
  margin: -12px 0 17px;
  text-align: center;
}
.modal .modal-dialog .modal-content .my-modal .btn:hover,
.modal .modal-dialog .modal-content .my-modal .btn:focus {
  color: white;
}
.modal .modal-dialog .modal-content .my-modal .modal-header {
  border: none;
  border-radius: 20px 20px 0 0;
  background-color: #f8f8f8;
  margin-bottom: 25px;
  box-shadow: 0 1px 1px #d50c7c;
  border-bottom: 2px solid #d50c7c;
  color: #515151;
}
.modal .modal-dialog .modal-content .my-modal .modal-header .btn-close {
  position: absolute;
  background-color: #f8f8f8;
  right: -20px;
  padding: 13px;
  top: 0;
  z-index: 100;
  border-radius: 0 20px 20px 0;
}
.modal .modal-dialog .modal-content .my-modal .modal-header .modal-title {
  text-align: center;
  font-size: 1.4rem;
}
.error {
  background-color: #f53c56;
  color: white;
  font-size: 0.9rem;
  padding: 2px 6px 3px;
  margin-top: 5px;
}
.or-separator {
  position: relative;
  margin-top: 24px;
  margin-bottom: 22px;
}
.or-separator > hr {
  height: 1px;
  border: none;
  background-color: #404040;
}
.or-separator > .label-holder {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: -13px;
}
.or-separator > .label-holder > span {
  display: block;
  margin: 0 auto;
  width: 64px;
  background-color: white;
}
.my-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.my-ellipsis-col {
  float: left;
}
i.float-help {
  cursor: pointer;
  display: none;
  color: #3D3C49;
}
i.float-help.absolute {
  position: absolute;
  top: 0;
  right: 0;
}
body.show-float-help i.float-help {
  display: inline-block;
}
body.show-float-help .header,
body.show-float-help #curr-song-holder,
body.show-float-help #main-error,
body.show-float-help #main-notification {
  position: absolute;
}
#connectionProblemModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
}
#connectionProblemModal > .messageContainer {
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 5px;
  font-size: 1.2rem;
}
#connectionProblemModal > .messageContainer .message {
  font-weight: bold;
  padding: 1px 0 5px;
  margin-right: 8px;
}
#connectionProblemModal > .messageContainer button {
  padding: 4px 9px 5px;
  font-size: 1rem;
}
#connectionProblemModal > .overlay {
  background-color: #000;
  opacity: 0.3;
  height: 100%;
  width: 100%;
}
.header .user-details .user-name a {
  text-decoration: none;
  color: #fff;
  letter-spacing: -1px;
}
.header .user-details .user-name a:hover {
  text-decoration: underline;
}
.throw-far-left {
  position: absolute;
  left: -99999px;
}
.horizontal {
  padding: 0;
  margin: 0;
}
.horizontal li {
  display: inline;
}
.zebraList > * {
  display: flex;
  align-items: center;
  line-height: 20px;
}
.zebraList a {
  width: 100%;
}
.zebraList a:hover {
  text-decoration: none;
}
.zebraList > :nth-child(odd) {
  background-color: #f8f8f8;
}
.zebraList > :nth-child(even) {
  background-color: #e6e6e6;
}
.zebraList > :hover {
  background-color: #dfdfdf !important;
}
.noSelection {
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}
.slash {
  color: #f32196;
  text-shadow: 1px 1px 1px #2196F3;
  margin: 0 8px;
  font-weight: 200;
}
.my-scrollable {
  overflow: hidden !important;
  position: relative;
}
.my-scrollable .ps-scrollbar-y-rail {
  opacity: 0.5;
  z-index: 1;
  width: 12px;
  height: 100% !important;
  background-color: #0c7cd5;
  border-radius: 0;
  right: 0px;
}
.my-scrollable .ps-scrollbar-y {
  border-radius: 2px !important;
  right: 0px !important;
}
.my-scrollable .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
}
.grabCursor {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.my-scrollable.bright .ps-scrollbar-y-rail,
.my-scrollable.bright .ps-scrollbar-y-rail:hover,
.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: inherit;
  border-radius: 2px !important;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  background-color: rgba(0, 0, 0, 0.6);
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 2s;
  /* Chrome, Safari, Opera */
  animation-duration: 2s;
  webkit-animation-iteration-count: infinite;
  /* Chrome, Safari, Opera */
  animation-iteration-count: infinite;
}
#page-preloader {
  opacity: 1;
  letter-spacing: -0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#page-preloader .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#page-preloader .content {
  color: white;
  position: relative;
  margin-top: -9%;
}
#page-preloader .content .pumpingLetter {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 2s;
  /* Chrome, Safari, Opera */
  animation-duration: 2s;
  webkit-animation-iteration-count: infinite;
  /* Chrome, Safari, Opera */
  animation-iteration-count: infinite;
  font-size: 100px;
  line-height: 70px;
  transform: scale(0.9, 0.9);
  -webkit-transform: scale(0.9, 0.9);
}
#page-preloader .content .title {
  color: white;
  margin-top: 10px;
  font-size: 35px;
}
#page-preloader .content .sub-title {
  font-size: 1.4rem;
  line-height: 21px;
  margin-top: 10px;
  letter-spacing: 0.1px;
  color: white;
}
.relative-parent {
  position: relative;
}
textarea {
  color: black;
}
#footer {
  position: fixed;
  width: 100%;
  z-index: 3;
  bottom: 0px;
  pointer-events: none;
}
#footer .footerWidgets {
  float: right;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  pointer-events: auto;
  flex-direction: column;
}
#footer .footerWidgets .footerWidget {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 8px;
  cursor: pointer;
}
#footer .footerWidgets .footerWidget:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
#footer .footerWidgets .createFeedDrop {
  background-color: rgba(0, 0, 0, 0.6);
}
#footer .footerWidgets .createFeedDrop:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
#footer .footerWidgets.inRoomList .footerWidget {
  background-color: rgba(0, 0, 0, 0.6);
}
#footer .footerWidgets #createBeatRoomBtn {
  text-decoration: none;
}
#footer .footerWidgets .scrollDown {
  text-decoration: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 55px;
  bottom: 7px;
  opacity: 0.8;
  font-size: 0.8rem;
}
#footer .footerWidgets .scrollDown:hover {
  opacity: 1;
}
#footer .footerWidgets #feedbackWidget .btnOpener {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9rem;
}
#footer .footerWidgets #feedbackWidget .btnOpener > div b {
  font-weight: 100;
}
#footer .footerWidgets #feedbackWidget #feedbackFrom {
  position: absolute;
  z-index: 1002;
  text-align: center;
  line-height: 15px;
  bottom: 0px;
  right: 0px;
}
#footer .footerWidgets #feedbackWidget .congratsMsg,
#footer .footerWidgets #feedbackWidget .frmHolder {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 12px 10px 9px;
  display: table;
  width: 322px;
  text-align: left;
  flex-direction: column;
  display: flex;
}
#footer .footerWidgets #feedbackWidget .congratsMsg .fa-times,
#footer .footerWidgets #feedbackWidget .frmHolder .fa-times {
  right: 10px;
  top: 5px;
  position: absolute;
}
#footer .footerWidgets #feedbackWidget .congratsMsg label,
#footer .footerWidgets #feedbackWidget .frmHolder label {
  color: white;
  margin-bottom: 8px;
  line-height: 20px;
}
#footer .footerWidgets #feedbackWidget .congratsMsg textarea,
#footer .footerWidgets #feedbackWidget .frmHolder textarea,
#footer .footerWidgets #feedbackWidget .congratsMsg input,
#footer .footerWidgets #feedbackWidget .frmHolder input {
  border: none;
  width: 300px;
  margin-bottom: 2px;
  color: #3D3C49 !important;
  padding: 5px 6px 6px;
}
#footer .footerWidgets #feedbackWidget .congratsMsg textarea:hover,
#footer .footerWidgets #feedbackWidget .frmHolder textarea:hover,
#footer .footerWidgets #feedbackWidget .congratsMsg input:hover,
#footer .footerWidgets #feedbackWidget .frmHolder input:hover {
  background-color: white !important;
}
#footer .footerWidgets #feedbackWidget .congratsMsg textarea:active,
#footer .footerWidgets #feedbackWidget .frmHolder textarea:active,
#footer .footerWidgets #feedbackWidget .congratsMsg input:hover,
#footer .footerWidgets #feedbackWidget .frmHolder input:hover {
  background-color: white !important;
}
#footer .footerWidgets #feedbackWidget .congratsMsg textarea,
#footer .footerWidgets #feedbackWidget .frmHolder textarea {
  height: 100px;
  max-height: 500px;
  max-width: 500px;
}
#footer .footerWidgets #feedbackWidget .congratsMsg button,
#footer .footerWidgets #feedbackWidget .frmHolder button {
  margin-top: 8px;
  width: 100%;
  border-radius: 0px;
}
#footer .footerWidgets #feedbackWidget .congratsMsg input[type="email"],
#footer .footerWidgets #feedbackWidget .frmHolder input[type="email"] {
  color: #000;
  margin-bottom: 3px;
}
#footer .footerWidgets #feedbackWidget .congratsMsg .error,
#footer .footerWidgets #feedbackWidget .frmHolder .error {
  padding: 3px 0px;
}
#footer .footerWidgets #feedbackWidget .congratsMsg {
  line-height: 20px;
  padding: 18px 14px 18px;
  white-space: initial;
}
#footer .footerWidgets #feedbackWidget .congratsMsg h3 {
  margin: 0px;
  margin-bottom: 6px;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently*/
}
.nothingButEcho {
  color: #6f6f6f;
}
.readMoreElement {
  position: relative;
  overflow: hidden;
}
.readMoreElement .readMoreButton {
  position: absolute;
  bottom: 0px;
  padding-left: 10px;
  color: #0c7cd5;
  right: 11px;
  cursor: pointer;
  font-weight: bold;
  background-color: #f8f8f8;
}
.letterCounterHolder {
  position: relative;
}
.letterCounterHolder .letterCounter {
  position: absolute;
  bottom: 9px;
  right: 13px;
  background-color: white;
  padding-left: 6px;
  color: #0c7cd5;
  line-height: 18px;
}
.letterCounterHolder .letterCounter.overflow {
  color: red;
}
.biography {
  padding: 6px 9px 8px 10px;
  word-break: break-word;
  background-color: #f8f8f8;
  border: 2px dotted lightgrey;
  border-radius: 10px;
  color: black;
}
.biography .view {
  background-color: #f8f8f8;
}
.biography .view i {
  color: #0c7cd5;
}
.biography a {
  color: blue;
}
.biography a:hover {
  text-decoration: underline;
}
#christmasModeCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}
.tags > div {
  display: inline;
  border-radius: 2px;
  padding: 4px 7px 6px;
  margin-right: 3px;
  background-color: #f32196;
  color: white;
  white-space: nowrap;
  line-height: 26px;
  font-size: 0.9rem;
}
.userMention {
  color: #f32196;
}
.userMention:hover {
  color: #f32196;
}
.tag-item {
  background: #f32196 !important;
  color: white !important;
  border: none !important;
  height: 21px !important;
  line-height: 21px !important;
}
.tag-item .remove-button {
  color: white !important;
}
.tag-item .remove-button:hover {
  text-decoration: none !important;
}
.noSelection {
  user-select: none;
  /* standard syntax */
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}
.noscroll {
  overflow: hidden;
}
.grecaptcha-badge {
  display: none !important;
}
.hideNumberArrows::-webkit-inner-spin-button,
.hideNumberArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numberBubble {
  position: absolute;
  background: #FF8181;
  width: 13px;
  height: 13px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 50%;
  font-weight: bold;
  z-index: 1;
  top: 12px;
  right: 6px;
  pointer-events: none;
}
.hashtag {
  color: #0c7cd5;
  text-decoration: underline;
  cursor: pointer;
}
.userImage {
  border-radius: 50%;
  margin-right: 10px;
}
.userImage.small {
  width: 30px;
  height: 30px;
}
.userImage.medium {
  width: 50px;
  height: 50px;
}
.userImage.big {
  width: 100px;
  height: 100px;
}
