@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";

//***********  blink ************
.blink(@from, @to, @duration){
  .blinkFunc{
    from {
      opacity: @from;
    }
    to {
      opacity: @to;
    }
  }
  @-webkit-keyframes blink {
    .blinkFunc;
  }
  @keyframes blink {
    .blinkFunc;
  }
  -webkit-animation:  blink @duration infinite;
  animation: blink @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.blink2(@from, @to, @duration){
  .blinkFunc{
    from {
      opacity: @from;
    }
    to {
      opacity: @to;
    }
  }
  @-webkit-keyframes blink {
    .blinkFunc;
  }
  @keyframes blink {
    .blinkFunc;
  }
  -webkit-animation:  blink @duration infinite;
  animation: blink @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.blink3(@from, @to, @duration){
  .blinkFunc{
    from {
      opacity: @from;
    }
    to {
      opacity: @to;
    }
  }
  @-webkit-keyframes blink {
    .blinkFunc;
  }
  @keyframes blink {
    .blinkFunc;
  }
  -webkit-animation:  blink @duration infinite;
  animation: blink @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.blink4(@from, @to, @duration){
  .blinkFunc{
    from {
      opacity: @from;
    }
    to {
      opacity: @to;
    }
  }
  @-webkit-keyframes blink {
    .blinkFunc;
  }
  @keyframes blink4 {
    .blinkFunc;
  }
  -webkit-animation:  blink4 @duration infinite;
  animation: blink4 @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.blinkOrangeBackground{
  .blinkBackground(244,103,72,0,0.7,4);
}
.blinkBackground(@r,@g,@b,@aFrom,@aTo,@times){
  @-webkit-keyframes blinkBackground {
    .blinkBackgroundFunc;
  }
  @keyframes blinkBackground {
    .blinkBackgroundFunc
  }
  .blinkBackgroundFunc{
    from {
      background-color: rgba(@r,@g,@b,@aFrom);
    }
    to {
      background-color: rgba(@r,@g,@b,@aTo);
    }
  }
  -webkit-animation: blinkBackground 0.3s @times;
  animation: blinkBackground 0.3s @times;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.blinkWithScale( @opacityFrom, @opacityTo, @scaleFrom, @scaleTo, @duration ){
  @-webkit-keyframes blinkWithScale {
    .blinkWithScaleFunc;
  }
  @keyframes blinkWithScale {
    .blinkWithScaleFunc
  }
  .blinkWithScaleFunc{
    from {
      opacity: @opacityFrom;
      transform: scale(@scaleFrom, @scaleFrom);
    }
    to {
      opacity: @opacityTo;
      transform: scale(@scaleTo, @scaleTo);
    }
  }
  -webkit-animation: blinkWithScale @duration infinite;
  animation: blinkWithScale @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.blinkWithScale2( @opacityFrom, @opacityTo, @scaleFrom, @scaleTo, @duration ){
  @-webkit-keyframes blinkWithScale2 {
    .blinkWithScaleFunc2;
  }
  @keyframes blinkWithScale2 {
    .blinkWithScaleFunc2
  }
  .blinkWithScaleFunc2{
    from {
      opacity: @opacityFrom;
      transform: scale(@scaleFrom, @scaleFrom);
    }
    to {
      opacity: @opacityTo;
      transform: scale(@scaleTo, @scaleTo);
    }
  }
  -webkit-animation: blinkWithScale2 @duration infinite;
  animation: blinkWithScale2 @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.noblink {
  animation-iteration-count: 0 !important;
}

@-webkit-keyframes pulse {
  0% {  -webkit-transform: scale(0.9, 0.9); }
  8% {  -webkit-transform: scale(1.05, 1.05); }
  20% { -webkit-transform: scale(0.9, 0.9); }
  28% { -webkit-transform: scale(1.05, 1.05); }

}
@keyframes pulse {
  0% {  transform: scale(0.9, 0.9); }
  6% {  transform: scale(1.05, 1.05); }
  12% {  transform: scale(0.9, 0.9);  }
  18% {  transform: scale(1.05, 1.05);  }
  24% {  transform: scale(0.9, 0.9);  }

}

.blinkBg(@from, @to, @duration){

  .blinkBgFunc{
      from {
        background-color: @color0;
      }
      to {
        background-color: @color2;
      }
  }

  @-webkit-keyframes blinkBgFunc2 {
    .blinkBgFunc;
  }
  @keyframes blinkBgFunc2 {
    .blinkBgFunc;
  }

  -webkit-animation:  blinkBgFunc2 @duration infinite;
  animation: blinkBgFunc2 @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.blinkColor(@from, @to, @duration){
@color0: @from;
  .blinkBgFunc{
    from {
      color: @color0;
    }
    to {
      color: @color2;
    }
  }

  @-webkit-keyframes blinkBgFunc2 {
    .blinkBgFunc;
  }
  @keyframes blinkBgFunc2 {
    .blinkBgFunc;
  }

  -webkit-animation:  blinkBgFunc2 @duration infinite;
  animation: blinkBgFunc2 @duration infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.glow-on-hover {
  position: relative;
  z-index: 0;
  &:hover{
    color: white !important;
  }
  &.static:before{
    opacity: 1;
  }
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: 0px;
  left:0px;
  background-size: 400%;
  z-index: -1;
  width: calc(100%);
  height: calc(100%);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}