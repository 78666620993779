@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";
@import (reference) "general";

.searchBoxHolder {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: white;
    .border;
    padding: 3px 10px;
    width: 100%;
    font-weight: normal;
    //&:focus-within{
    //    box-shadow: inset -1px -1px 1px gray;
    //}
    input{
        border: none;
        height: 20px;
        flex-grow: 2;
        max-width: 188px;
    }
    .fa-search {
        position: relative;
        z-index: 1;
        color: gray;
    }
    i {
        position: relative;
        z-index: 1;
        color: @lightGray;
        &.withText{
            color: @grayText;
        }

        &.disabled {
            color: @lightGray;
            cursor: default;
        }
    }
}
