@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) 'fonts';
@import (reference) 'variables';
@import 'beats';

html{
    font-size: 14px;
    body{
        font-family: @fontFamily;
        color: @textColor;
        transition-duration: .5s;
        -webkit-transition-property: background-color,border-color;
        transition-property: background-color,border-color;
        overflow-x: hidden;
        background-color: @bgColor_lighter;
        min-width: @homepageWidth;
        &.modal-open {
            overflow: inherit;
            overflow-x: hidden;
        }

        h1{
            .giantText;
            font-weight: bold;
        }
        h2{
            .bigText();
             margin: 0px 0px 8px 0px;
        }
        h3{
            .mediumText();
            margin: 0px 0px 8px 0px;
        }
        h3, h4, h2{
            font-weight: 100;
        }
        h6{
            font-weight: bold;
        }
        input[type="radio"], input[type="checkbox"]{
            margin: 0px 3px 0px 0px;
        }

        &.innerPlayer {
            overflow: hidden;
        }
    }
}

.facebook {
    background-color: @facebookBlue;
}
.twitter {
    background-color: @twitter;
}

.abs(@width, @height, @top, @right, @bottom, @left){
    position: absolute;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
    width: @width;
    height: @height;
}

@borderColor: #e8e8e8;
.border{
    border: 1px solid @borderColor;
}

.box{
    .border;
    border-radius: @borderRadius;
    background-color: white;
    padding: 10px;
}

.fa-times{
    cursor: pointer;
}

ul {
    list-style-type: none;
    padding: 0;
}

.preloaderWrapper{
    width: 100%;
    justify-content: center;
    display: flex;
    font-size: 50px;
    padding: @spacing 0px;
    color: @grayText;
}

input[type='text'], input[type='email'], input[type='password']{
    height: 28px;
    color: black;
    position: relative;
    padding: 1px 10px 3px;
    border: @border;
}

.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

a, a:hover, a:active, a:focus{
    color: inherit;
}

.background-audio{
    display: none;
}

.underline {
    text-decoration: underline;
}

.fa-bolt{
    color: @beats;
    margin-left: 3px;
}
.orange {
    color: @color2;
}
.bold {
    font-weight: bold;
}

.bolder {
    font-weight: 100;
}

a:focus{
    text-decoration: none;
}
.white {
    color: white !important;
}

.red {
    color: @color2
}

.bigBtn {
    font-size: 1em !important;
    padding: 4px 20px;
}

/*Bootstrap fixes*/

.label-default {
    background: none;
}

.badge {
    background: none;
    padding: 0;
    margin: 0;
}

.btn-primary {
    border: none;
}

.btn, button {
    &:hover, &:active, &:focus{
        background-color: @color2_dark;
        color: white;
    }
    .bg-transition();
    background-color: @color2;
    color: white;
    border-radius: @borderRadius;
    border: none;
    padding: 8px 13px 10px;
    outline: none;
    .beatsWrapper{
        .smallText();
        margin-left: 2px;
        color: yellow;
        img{
            height: 16px;
            bottom: 2px;
            margin-left: 1px;
            position: relative;
        }
    }
    &:hover{
        background-color: @color2_dark;
    }
    &.disabled{
        text-decoration: none !important;
        cursor: default !important;
        background-color: gray !important;
        background: gray !important;
    }
    &.secondary{
        background-color: @color0 !important;
        &:hover{
            background-color: @color0_dark !important;
        }
    }
}
:focus{
    outline: none !important;
}

.btn-default, .btn-default:hover, .btn-default:active, .btn-default:focus {
    color: #ffffff;
}


.login-modal, .register-modal {
    .msgArea{
        text-align: center;
        margin-top: -13px;
        margin-bottom: 15px;
        color: @grayText;
    }
    .signupLoginArea{
        max-width: 275px;
        margin: 0 auto;
        .error{
            padding-left: 9px;
            padding-bottom: 2px;
            margin-bottom: 5px;
        }
        input{
            height: 29px;
        }
        .form-control{
            margin-bottom: 7px;
            &[type=text]::placeholder, &[type=password]::placeholder{
                color: darkgray;
                .smallText();
            }
        }
        .buttons-holder{
            margin-top: 10px;
            margin-bottom: 11px;
            button {
                width: 100%;
                font-weight: normal;
            }
            #signUpLoginBtn{
                background-color: @color0;
                padding: 7px 5px 9px;
                &:hover{
                    background-color: @color0_dark;
                }
                i{
                    top: 2px;
                    position: relative;
                }
            }
        }
        .additional-ops-link{
            color: @color2;
            .smallText();
            margin-top: 14px;
        }

        #verificationCode{
            input{
                margin: 6px 0;
            }
            a{
                color: @color0;
            }
        }

        .approveTos {
            display: flex;
            color: gray;
            a{
                color: @color0_dark;
                text-decoration: underline;
            }
            input {
                height: auto;
            }
            label {
                font-weight: normal !important;
                font-size: 1rem !important;;
                margin-bottom: 0 !important;;
            }
        }
    }
}

.online{
    color: @onlineGreen;
}

.login-modal{
    .buttons-holder{
        text-align: center;
        a{
            top: 3px;
        }
    }
}

.forgotPasswordModal{
    .txtEmail{
        margin-bottom: 8px;
        width: 100%;
        text-align: center;
        margin-top: 1px;
    }
    .buttons-holder{
        display: flex;
        justify-content: center;
        a{
            margin-left: 5px;
        }
    }
}

.form-group {
    margin: 0;
}

.form-control {
    margin-bottom: 6px;
    border-radius: 0;
    padding: 0 0 0 10px;
    box-shadow: none;
}

label {
    font-weight: normal;
    margin-bottom: 2px;
}

.modal-footer {
    text-align: left;
    margin-top: 10px;
}

/*End bootstrap fixes*/

input::-ms-clear {
    display: none;
}

div.tooltip-inner {
    text-align: left;
    white-space: pre-wrap !important;
    max-width: 500px;
    letter-spacing: 0.2px !important;
    //white-space: nowrap;
    background-color: @color1;

}
.tooltip.in{
    opacity: 1;
}

.tooltip.top .tooltip-arrow {
    border-top-color: @color1;
}
.tooltip.top-left .tooltip-arrow{
    border-top-color: @color1;
}
.tooltip.top-right .tooltip-arrow {
    border-top-color: @color1;
}
.tooltip.right .tooltip-arrow {
    border-right-color: @color1;
}
.tooltip.left .tooltip-arrow {
    border-left-color: @color1;
}
.tooltip.bottom .tooltip-arrow {
    border-bottom-color: @color1;
}
.tooltip.bottom-left .tooltip-arrow {
    border-bottom-color: @color1;
}
.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: @color1;
}

.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    left: 5px;
    border-width: 5px 5px 0;
    //border-top-color: @color1;
}

a, a:focus {
    outline: 0 !important;
}

.form-group{
    &.tags{
        display: table;
        width: 100%;
    }
}

#main-error, #main-notification {
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    position: fixed;
    z-index: 1051;
    font-weight: bold;
    line-height: 50px;
    padding: 3px 0 5px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    background-color: @color0_transparent !important;
    color: white;
    .bigText;
    .clickable;
    button{
        height: 30px;
        .flex;
        .mediumText;
        margin-left: 10px;
        background-color: @color0_dark;
    }
}

.logoFont, .project-name{
    font-family: 'OleoScript', cursive;
}

.introjs-tooltip{
    min-width: 300px;
    max-width: inherit;
    padding: 10px 17px 20px;
    .introjs-tooltiptext{
        h1{
            margin: 8px 0px;
            .bigText;
        }
    }
}
.introjs-helperLayer{
    background-color: rgba(255,255,255,0.2);
}

.introjs-bullets ul li a{
    background: @color1;
    &.active{
        background: @color0;
    }
}
.introjs-tooltipbuttons a, .introjs-disabled:focus, .introjs-disabled, .introjs-disabled:hover, .introjs-button:focus, .introjs-button:active{
    color: white;
    background-color: @color1;
    background-image: none;
    border: none;
    font-weight: normal;
    text-shadow: none;
}
.introjs-disabled, .introjs-disabled:hover{
    background-color: lighten(@color1,50%);
}

.beats{
    display: inline-flex;
    align-items: center;
}
.beatsWrapper{
    margin-left: 2px;
    color: yellow;
    img{
        height: 16px;
        bottom: 2px;
        margin-left: 1px;
        position: relative;
    }
}

.main {

    > .container {
        width: 1070px;
        position: relative;
    }
    &.admin{
      margin-top: 50px;
      .table-bordered{
        border: 1px solid @color1;
      }
    }
    #mainBanner{
        // staticBaseUrl comes from WebPack less loader
        background-image: url("@{staticBaseUrl}/images/themes/beach.jpg");
        & > video{
            position: absolute;
            width: 100%;
            top: 0px;
            left: 0px;
        }
    }
}

.field-error {
    color: @red;
    padding-bottom: 5px;
}

.nopadding {
    padding: 0 !important;
}

.nomargin {
    margin: 0 !important;
}


.link:hover {
    text-decoration: underline;
}

.clear {
    clear: both;
}

.lighter {
    font-weight: normal;
}

.modal{
    display: flex !important;
    .modal-dialog{
        margin: auto;
        width: auto;
        .modal-content {
            min-width: 350px;
            max-width: 636px;
            border: none;
            background-color: inherit;
            border-radius: @borderRadius;
            .modal-body {
                padding: 0px 20px 20px;
                label{
                    font-weight: bold;
                    margin-bottom: 4px;
                }
            }
            .my-modal{
                position: relative;
                border-radius: @borderRadius;
                background-color: white;
                .small-link {
                    .smallerText;
                    color: @grayText;
                    margin-top: 38px;
                    position: relative;
                    top: 3px;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                .additional-ops-link {
                    text-align: center;
                    a{
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                form .buttons-holder {
                    display: table;
                    width: 100%;
                    margin-top: 12px;
                }
                .modal-body > .state-message {
                    line-height: 18px;
                    margin: -12px 0 17px;
                    text-align: center;
                }
                .btn:hover, .btn:focus {
                    color: white;
                }
                .modal-header {
                    border: none;
                    border-radius: @borderRadius @borderRadius 0 0;
                    background-color: @bgColor_lighter;
                    margin-bottom: 25px;
                    box-shadow: 0 1px 1px @color2_dark;
                    border-bottom: 2px solid @color2_dark;
                    color: #515151;
                    .btn-close {
                        position: absolute;
                        background-color: @bgColor_lighter;
                        right: -20px;
                        padding: 13px;
                        top: 0;
                        z-index: 100;
                        border-radius: 0 @borderRadius @borderRadius 0;
                    }
                    .modal-title {
                        text-align: center;
                        .bigText;
                    }
                }
            }
        }
    }
}

.error {
    background-color: @red;
    color: white;
    .smallText;
    padding: 2px 6px 3px;
    margin-top: 5px;
}

.or-separator {
    position: relative;
    margin-top: 24px;
    margin-bottom: 22px;
    > hr {
        height: 1px;
        border: none;
        background-color: @textColor;
    }
    > .label-holder {
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: -13px;
        > span {
            display: block;
            margin: 0 auto;
            width: 64px;
            background-color: white;
        }
    }
}


.my-ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.my-ellipsis-col {
    float: left;
}

i.float-help {
    cursor: pointer;
    display: none;
    color: @color1;
}

i.float-help.absolute {
    position: absolute;
    top: 0;
    right: 0;
}

body.show-float-help i.float-help {
    display: inline-block;
}

body.show-float-help .header,
body.show-float-help #curr-song-holder,
body.show-float-help #main-error,
body.show-float-help #main-notification {
    position: absolute;
}

#connectionProblemModal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2000;
    > .messageContainer {
        bottom: 0;
        position: absolute;
        width: 100%;
        background-color: rgba(255,255,255,0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 8px 5px;
        .mediumText;
        .message {
            font-weight: bold;
            padding: 1px 0 5px;
            margin-right: 8px;
        }
        button{
            padding: 4px 9px 5px;
            font-size: 1rem;
        }
    }
    > .overlay {
        background-color: #000;
        opacity: 0.3;
        height: 100%;
        width: 100%;
    }
}

.header .user-details{
    .user-name a {
        text-decoration: none;
        color: #fff;
        letter-spacing: -1px;
        &:hover {
            text-decoration: underline;
        }
    }
}

.throw-far-left {
    position: absolute;
    left: -99999px;
}

.horizontal {
    padding: 0;
    margin: 0;
    li {
        display: inline;
    }
}

.zebraList{
    > * {
        display: flex;
        align-items: center;
        line-height: 20px;
    }
    a{
        width: 100%;
        &:hover{
            text-decoration: none;
        }
    }
    > :nth-child(odd) {
        background-color: @bgColor_lighter;
    }
    > :nth-child(even) {
        background-color: @bgColor;
    }
    > :hover{
        background-color: @bgColor_hover !important;
    }
}

.noSelection {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.slash{
    color: @color2;
    text-shadow: 1px 1px 1px @color0;
    margin: 0 8px;
    font-weight: 200;
}

//************************

.my-scrollable{
  overflow: hidden !important;
  position: relative;
  .ps-scrollbar-y-rail {
    opacity: 0.5;
    z-index: 1;
    width: 12px;
    height: 100% !important;
    background-color: @color0_dark;
    border-radius: 0;
    right: 0px;
  }
  .ps-scrollbar-y {
    border-radius: 2px !important;
    right: 0px !important;
  }
  .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: rgba(255,255,255,0.5) ;
    width: 100%;
  }
}

.grabCursor{
    cursor: grab;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}
.my-scrollable.bright .ps-scrollbar-y-rail, .my-scrollable.bright .ps-scrollbar-y-rail:hover, .ps-container:hover > .ps-scrollbar-y-rail:hover {
    background-color: inherit;
    border-radius: 2px !important;
    .grabCursor
}
.ps-container.ps-active-x > .ps-scrollbar-x-rail, .ps-container.ps-active-y > .ps-scrollbar-y-rail{
    background-color: @glassBg_darker;
    .grabCursor
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 2s; /* Chrome, Safari, Opera */
    animation-duration: 2s;
    webkit-animation-iteration-count: infinite;  /* Chrome, Safari, Opera */
    animation-iteration-count: infinite;
}

#page-preloader {
    opacity: 1;
    letter-spacing: -0.3px;
    .flex;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //.gradient-generator( @color0_lightest, @color0_dark, '0%' );
        //opacity: 0.8;
    }
    .content {
        color: white;
        position: relative;
        margin-top: -9%;
        .pumpingLetter{
          .pulse;
          font-size: 100px;
            line-height: 70px;
          transform: scale(0.9,0.9);
          -webkit-transform: scale(0.9,0.9);
        }
        .title {
            color: white;
            margin-top: 10px;
            font-size: 35px;
        }
        .sub-title {
            .bigText;
            line-height: 21px;
            margin-top: 10px;
            letter-spacing: 0.1px;
            color: white;
        }
    }
}

.relative-parent {
    position: relative;
}

textarea{
    color: black;
}

#footer{
    position: fixed;
    width: 100%;
    z-index: 3;
    bottom: 0px;
    pointer-events: none; //not to block stuff when console is on
    .footerWidgets{
        float: right;
        padding: 0px 8px;
        .flex;
        pointer-events: auto;
        flex-direction: column; //to complument the above comment
        .footerWidget{
            width: 40px;
            height: 40px;
            background-color: @glassBg_darker;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            margin-bottom: 8px;
            cursor: pointer;

            &:hover{
                background-color: @glassBg_evenDarker !important;
            }
        }
        .createFeedDrop{
            background-color: @glassBg_darker;

            &:hover{
                background-color: @glassBg_evenDarker !important;
            }
        }
        &.inRoomList{
            .footerWidget {
                background-color: @glassBg_darker;
            }
        }
        #createBeatRoomBtn{
            text-decoration: none;
        }
        .scrollDown{
            text-decoration: none;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 55px;
            bottom: 7px;
            opacity: 0.8;
            .smallerText;
            &:hover{
                opacity: 1;
            }
        }
        #feedbackWidget {
            .btnOpener{
                cursor: pointer;
                font-weight: bold;
                .smallText;
                > div{
                    b{
                        font-weight: 100;
                    }
                }
            }
            #feedbackFrom{
                position: absolute;
                z-index: 1002;
                text-align: center;
                line-height: 15px;
                bottom: 0px;
                right: 0px;
            }

            .congratsMsg, .frmHolder {
                background-color: @glassBg_darker;
                color: white;
                padding: 12px 10px 9px;
                display: table;
                width: 322px;
                text-align: left;
                flex-direction: column;
                display: flex;
                .fa-times{
                    right: 10px;
                    top: 5px;
                    position: absolute;
                }

                label {
                    color: white;
                    margin-bottom: 8px;
                    line-height: 20px;
                }
                textarea, input {
                    border: none;
                    width: 300px;
                    margin-bottom: 2px;
                    color: @color1 !important;
                    padding: 5px 6px 6px;
                }
                textarea:hover, input:hover {
                    background-color: white !important;
                }
                textarea:active, input:hover {
                    background-color: white !important;
                }
                textarea {
                    height: 100px;
                    max-height: 500px;
                    max-width: 500px;
                }

                button {
                    margin-top: 8px;
                    width: 100%;
                    border-radius: 0px;
                }
                input[type="email"] {
                    color: #000;
                    margin-bottom: 3px;
                }
                .error{
                    padding: 3px 0px;
                }
            }
            .congratsMsg {
                line-height: 20px;
                padding: 18px 14px 18px;
                white-space: initial;
                h3{
                    margin: 0px;
                    margin-bottom: 6px;
                }
            }
        }
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* IE/Edge */
    user-select: none;           /* non-prefixed version, currently*/
}

.nothingButEcho{
    color: @grayText;
}

.readMoreElement{
    position: relative;
    overflow: hidden;

    .readMoreButton{
        position: absolute;
        bottom: 0px;
        padding-left: 10px;
        color: @color0_dark;
        right: 11px;
        cursor: pointer;
        font-weight: bold;
        background-color: @bgColor_lighter;
    }
}

.letterCounterHolder{
    position: relative;

    .letterCounter{
        position: absolute;
        bottom: 9px;
        right: 13px;
        background-color: white;
        padding-left: 6px;
        color: @color0_dark;
        line-height: 18px;
        &.overflow{
            color: red;
        }
    }
}

.biography{
    padding: 6px 9px 8px 10px;
    word-break: break-word;
    background-color: @bgColor_lighter;
    border: 2px dotted lightgrey;
    border-radius: 10px;
    .view{
        background-color: @bgColor_lighter;
        i{
            color: @color0_dark;
        }
    }
    color: black;
    a{
        color: blue;
        &:hover{
            text-decoration: underline;
        }
    }
}

#christmasModeCanvas{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
}

.tags{
    > div{
        display: inline;
        border-radius: 2px;
        padding: 4px 7px 6px;
        margin-right: 3px;
        background-color: @color2;
        color: white;
        white-space: nowrap;
        line-height: 26px;
        .smallText;
    }
}

.userMention{
    color: @color2;
    &:hover{
        color: @color2;
    }
}

.tag-item {
    background: @color2 !important;
    color: white !important;
    border: none !important;
    height: 21px !important;
    line-height: 21px !important;
    .remove-button{
        color: white !important;
        &:hover{
            text-decoration: none !important;
        }
    }
}

.noSelection{
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}
.noscroll{
    overflow: hidden;
}

.grecaptcha-badge {
    display:none !important;
}

.hideNumberArrows {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.numberBubble{
    position: absolute;
    background: @red2;
    width: 13px;
    height: 13px;
    .tinyText;
    .flex();
    border-radius: 50%;
    font-weight: bold;
    z-index: 1;
    top: 12px;
    right: 6px;
    pointer-events: none;
}

.hashtag {
    color: @color0_dark;
    text-decoration: underline;
    .clickable
}

.userImage {

    border-radius: 50%;
    margin-right: 10px;

    &.small {
        width: 30px;
        height: 30px;

    }

    &.medium {
        width: 50px;
        height: 50px;
    }

    &.big {
        width: 100px;
        height: 100px;
    }
}