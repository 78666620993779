#add-song-to-beat-box-menu-holder {
  position: absolute;
  top: 100px;
  left: 500px;
  background-color: #fff;
  padding: 6px 10px 10px;
  border: 1px solid #D2D2D2;
  z-index: 1051;
  font-size: 0.9rem;
  display: none;
}
#add-song-to-beat-box-menu-holder .btnClose {
  color: #666666;
  position: absolute;
  right: 5px;
  top: 3px;
  cursor: pointer;
}
#add-song-to-beat-box-menu-holder input {
  border: 1px solid #2196F3;
  padding-left: 4px;
  margin-top: 6px;
  position: relative;
  top: 2px;
}
#add-song-to-beat-box-menu-holder .message {
  color: #219820;
  margin-top: 5px;
  margin-right: 5px;
}
#add-song-to-beat-box-menu-holder .cases {
  max-height: 200px;
}
#add-song-to-beat-box-menu-holder .cases a {
  max-width: 200px;
  margin-bottom: 3px;
}
#add-song-to-beat-box-menu-holder .cases .case.scrolled {
  background-color: #efefef;
}
#add-song-to-beat-box-menu-holder .publishChkbx {
  border-bottom: 1px solid lightgray;
  padding-bottom: 7px;
}
#add-song-to-beat-box-menu-holder .newCase input {
  width: 100%;
}
#add-song-to-beat-box-menu-holder .newCase button {
  position: relative;
  top: 4px;
  padding: 3px 7px 4px;
}
#add-song-to-beat-box-menu-holder .title {
  font-weight: 200;
  margin: 5px 0px;
}
#add-song-to-beat-box-menu-holder .browse {
  margin: 3px 0 3px;
}
#add-song-to-beat-box-menu-holder .browse a {
  text-decoration: underline;
  position: relative;
  top: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #0c7cd5;
}
