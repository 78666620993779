@staticBaseUrl: "https://cdn.beatsense.com/public";
.emoji {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.emoji-rooster {
    background-image: url('../images/icons/emoji/rooster.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_circle {
    background-image: url('../images/icons/emoji/black_circle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-carousel_horse {
    background-image: url('../images/icons/emoji/carousel_horse.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-penguin {
    background-image: url('../images/icons/emoji/penguin.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-round_pushpin {
    background-image: url('../images/icons/emoji/round_pushpin.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rage4 {
    background-image: url('../images/icons/emoji/rage4.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-guardsman {
    background-image: url('../images/icons/emoji/guardsman.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-train2 {
    background-image: url('../images/icons/emoji/train2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-city_sunset {
    background-image: url('../images/icons/emoji/city_sunset.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mute {
    background-image: url('../images/icons/emoji/mute.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrows_clockwise {
    background-image: url('../images/icons/emoji/arrows_clockwise.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-articulated_lorry {
    background-image: url('../images/icons/emoji/articulated_lorry.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-love_letter {
    background-image: url('../images/icons/emoji/love_letter.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-golf {
    background-image: url('../images/icons/emoji/golf.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bangbang {
    background-image: url('../images/icons/emoji/bangbang.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u7981 {
    background-image: url('../images/icons/emoji/u7981.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tea {
    background-image: url('../images/icons/emoji/tea.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-small_orange_diamond {
    background-image: url('../images/icons/emoji/small_orange_diamond.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-six {
    background-image: url('../images/icons/emoji/six.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mortar_board {
    background-image: url('../images/icons/emoji/mortar_board.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-diamond_shape_with_a_dot_inside {
    background-image: url('../images/icons/emoji/diamond_shape_with_a_dot_inside.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bicyclist {
    background-image: url('../images/icons/emoji/bicyclist.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rage1 {
    background-image: url('../images/icons/emoji/rage1.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pencil2 {
    background-image: url('../images/icons/emoji/pencil2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-anger {
    background-image: url('../images/icons/emoji/anger.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-1234 {
    background-image: url('../images/icons/emoji/1234.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-postbox {
    background-image: url('../images/icons/emoji/postbox.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-horse_racing {
    background-image: url('../images/icons/emoji/horse_racing.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-movie_camera {
    background-image: url('../images/icons/emoji/movie_camera.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-100 {
    background-image: url('../images/icons/emoji/100.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock730 {
    background-image: url('../images/icons/emoji/clock730.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sos {
    background-image: url('../images/icons/emoji/sos.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-capital_abcd {
    background-image: url('../images/icons/emoji/capital_abcd.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-telephone_receiver {
    background-image: url('../images/icons/emoji/telephone_receiver.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mobile_phone_off {
    background-image: url('../images/icons/emoji/mobile_phone_off.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hotsprings {
    background-image: url('../images/icons/emoji/hotsprings.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-stuck_out_tongue {
    background-image: url('../images/icons/emoji/stuck_out_tongue.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-plus1 {
    background-image: url('../images/icons/emoji/plus1.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-calendar {
    background-image: url('../images/icons/emoji/calendar.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-whale2 {
    background-image: url('../images/icons/emoji/whale2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kissing_cat {
    background-image: url('../images/icons/emoji/kissing_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-droplet {
    background-image: url('../images/icons/emoji/droplet.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-gun {
    background-image: url('../images/icons/emoji/gun.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_lower_left {
    background-image: url('../images/icons/emoji/arrow_lower_left.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-calling {
    background-image: url('../images/icons/emoji/calling.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-necktie {
    background-image: url('../images/icons/emoji/necktie.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-large_orange_diamond {
    background-image: url('../images/icons/emoji/large_orange_diamond.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clubs {
    background-image: url('../images/icons/emoji/clubs.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-school {
    background-image: url('../images/icons/emoji/school.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-squirrel {
    background-image: url('../images/icons/emoji/squirrel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-chestnut {
    background-image: url('../images/icons/emoji/chestnut.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dvd {
    background-image: url('../images/icons/emoji/dvd.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-japan {
    background-image: url('../images/icons/emoji/japan.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-persevere {
    background-image: url('../images/icons/emoji/persevere.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fried_shrimp {
    background-image: url('../images/icons/emoji/fried_shrimp.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-raised_hand {
    background-image: url('../images/icons/emoji/raised_hand.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-put_litter_in_its_place {
    background-image: url('../images/icons/emoji/put_litter_in_its_place.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cn {
    background-image: url('../images/icons/emoji/cn.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-monkey_face {
    background-image: url('../images/icons/emoji/monkey_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heavy_division_sign {
    background-image: url('../images/icons/emoji/heavy_division_sign.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wine_glass {
    background-image: url('../images/icons/emoji/wine_glass.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-key {
    background-image: url('../images/icons/emoji/key.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-foggy {
    background-image: url('../images/icons/emoji/foggy.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-vibration_mode {
    background-image: url('../images/icons/emoji/vibration_mode.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_square {
    background-image: url('../images/icons/emoji/black_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-laughing {
    background-image: url('../images/icons/emoji/laughing.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-construction_worker {
    background-image: url('../images/icons/emoji/construction_worker.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ghost {
    background-image: url('../images/icons/emoji/ghost.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-scroll {
    background-image: url('../images/icons/emoji/scroll.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tshirt {
    background-image: url('../images/icons/emoji/tshirt.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-disappointed_relieved {
    background-image: url('../images/icons/emoji/disappointed_relieved.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-envelope {
    background-image: url('../images/icons/emoji/envelope.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-train {
    background-image: url('../images/icons/emoji/train.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_double_up {
    background-image: url('../images/icons/emoji/arrow_double_up.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-nose {
    background-image: url('../images/icons/emoji/nose.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_entry_sign {
    background-image: url('../images/icons/emoji/no_entry_sign.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-trophy {
    background-image: url('../images/icons/emoji/trophy.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tv {
    background-image: url('../images/icons/emoji/tv.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-date {
    background-image: url('../images/icons/emoji/date.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_lower_right {
    background-image: url('../images/icons/emoji/arrow_lower_right.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bust_in_silhouette {
    background-image: url('../images/icons/emoji/bust_in_silhouette.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-eyeglasses {
    background-image: url('../images/icons/emoji/eyeglasses.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bee {
    background-image: url('../images/icons/emoji/bee.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-icecream {
    background-image: url('../images/icons/emoji/icecream.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fries {
    background-image: url('../images/icons/emoji/fries.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-whale {
    background-image: url('../images/icons/emoji/whale.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pensive {
    background-image: url('../images/icons/emoji/pensive.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ram {
    background-image: url('../images/icons/emoji/ram.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heartbeat {
    background-image: url('../images/icons/emoji/heartbeat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-atm {
    background-image: url('../images/icons/emoji/atm.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mount_fuji {
    background-image: url('../images/icons/emoji/mount_fuji.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rage2 {
    background-image: url('../images/icons/emoji/rage2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-warning {
    background-image: url('../images/icons/emoji/warning.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-roller_coaster {
    background-image: url('../images/icons/emoji/roller_coaster.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kimono {
    background-image: url('../images/icons/emoji/kimono.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cherries {
    background-image: url('../images/icons/emoji/cherries.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_joker {
    background-image: url('../images/icons/emoji/black_joker.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-baby_bottle {
    background-image: url('../images/icons/emoji/baby_bottle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-euro {
    background-image: url('../images/icons/emoji/euro.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_small_square {
    background-image: url('../images/icons/emoji/white_small_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock5 {
    background-image: url('../images/icons/emoji/clock5.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-walking {
    background-image: url('../images/icons/emoji/walking.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-question {
    background-image: url('../images/icons/emoji/question.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_left {
    background-image: url('../images/icons/emoji/arrow_left.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-repeat_one {
    background-image: url('../images/icons/emoji/repeat_one.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bride_with_veil {
    background-image: url('../images/icons/emoji/bride_with_veil.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-diamonds {
    background-image: url('../images/icons/emoji/diamonds.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fountain {
    background-image: url('../images/icons/emoji/fountain.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-electric_plug {
    background-image: url('../images/icons/emoji/electric_plug.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-left_luggage {
    background-image: url('../images/icons/emoji/left_luggage.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tomato {
    background-image: url('../images/icons/emoji/tomato.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bookmark {
    background-image: url('../images/icons/emoji/bookmark.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-european_post_office {
    background-image: url('../images/icons/emoji/european_post_office.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-thumbsup {
    background-image: url('../images/icons/emoji/thumbsup.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-chocolate_bar {
    background-image: url('../images/icons/emoji/chocolate_bar.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-snowboarder {
    background-image: url('../images/icons/emoji/snowboarder.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-paperclip {
    background-image: url('../images/icons/emoji/paperclip.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-full_moon {
    background-image: url('../images/icons/emoji/full_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-oden {
    background-image: url('../images/icons/emoji/oden.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-phone {
    background-image: url('../images/icons/emoji/phone.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tm {
    background-image: url('../images/icons/emoji/tm.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ski {
    background-image: url('../images/icons/emoji/ski.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dash {
    background-image: url('../images/icons/emoji/dash.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u7a7a {
    background-image: url('../images/icons/emoji/u7a7a.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-baggage_claim {
    background-image: url('../images/icons/emoji/baggage_claim.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mountain_cableway {
    background-image: url('../images/icons/emoji/mountain_cableway.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sunflower {
    background-image: url('../images/icons/emoji/sunflower.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ice_cream {
    background-image: url('../images/icons/emoji/ice_cream.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bank {
    background-image: url('../images/icons/emoji/bank.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bar_chart {
    background-image: url('../images/icons/emoji/bar_chart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mans_shoe {
    background-image: url('../images/icons/emoji/mans_shoe.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-woman {
    background-image: url('../images/icons/emoji/woman.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-trident {
    background-image: url('../images/icons/emoji/trident.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-beetle {
    background-image: url('../images/icons/emoji/beetle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-railway_car {
    background-image: url('../images/icons/emoji/railway_car.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-person_with_pouting_face {
    background-image: url('../images/icons/emoji/person_with_pouting_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-monorail {
    background-image: url('../images/icons/emoji/monorail.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fist {
    background-image: url('../images/icons/emoji/fist.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bowling {
    background-image: url('../images/icons/emoji/bowling.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-imp {
    background-image: url('../images/icons/emoji/imp.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cloud {
    background-image: url('../images/icons/emoji/cloud.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-seven {
    background-image: url('../images/icons/emoji/seven.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-truck {
    background-image: url('../images/icons/emoji/truck.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-umbrella {
    background-image: url('../images/icons/emoji/umbrella.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smile_cat {
    background-image: url('../images/icons/emoji/smile_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-vertical_traffic_light {
    background-image: url('../images/icons/emoji/vertical_traffic_light.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pray {
    background-image: url('../images/icons/emoji/pray.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-baby_symbol {
    background-image: url('../images/icons/emoji/baby_symbol.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pill {
    background-image: url('../images/icons/emoji/pill.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-finnadie {
    background-image: url('../images/icons/emoji/finnadie.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sunrise_over_mountains {
    background-image: url('../images/icons/emoji/sunrise_over_mountains.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-skull {
    background-image: url('../images/icons/emoji/skull.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-small_red_triangle_down {
    background-image: url('../images/icons/emoji/small_red_triangle_down.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-house {
    background-image: url('../images/icons/emoji/house.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rewind {
    background-image: url('../images/icons/emoji/rewind.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-secret {
    background-image: url('../images/icons/emoji/secret.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hankey {
    background-image: url('../images/icons/emoji/hankey.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-partly_sunny {
    background-image: url('../images/icons/emoji/partly_sunny.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock12 {
    background-image: url('../images/icons/emoji/clock12.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-first_quarter_moon_with_face {
    background-image: url('../images/icons/emoji/first_quarter_moon_with_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-virgo {
    background-image: url('../images/icons/emoji/virgo.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_check_mark {
    background-image: url('../images/icons/emoji/white_check_mark.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cancer {
    background-image: url('../images/icons/emoji/cancer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dizzy_face {
    background-image: url('../images/icons/emoji/dizzy_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-grey_question {
    background-image: url('../images/icons/emoji/grey_question.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-moyai {
    background-image: url('../images/icons/emoji/moyai.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-moneybag {
    background-image: url('../images/icons/emoji/moneybag.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-zzz {
    background-image: url('../images/icons/emoji/zzz.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_good {
    background-image: url('../images/icons/emoji/no_good.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-girl {
    background-image: url('../images/icons/emoji/girl.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-large_blue_diamond {
    background-image: url('../images/icons/emoji/large_blue_diamond.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_up_down {
    background-image: url('../images/icons/emoji/arrow_up_down.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-octopus {
    background-image: url('../images/icons/emoji/octopus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-waning_crescent_moon {
    background-image: url('../images/icons/emoji/waning_crescent_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mag_right {
    background-image: url('../images/icons/emoji/mag_right.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tulip {
    background-image: url('../images/icons/emoji/tulip.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-handbag {
    background-image: url('../images/icons/emoji/handbag.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock1 {
    background-image: url('../images/icons/emoji/clock1.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-flower_playing_cards {
    background-image: url('../images/icons/emoji/flower_playing_cards.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mailbox_with_mail {
    background-image: url('../images/icons/emoji/mailbox_with_mail.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-yum {
    background-image: url('../images/icons/emoji/yum.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-basketball {
    background-image: url('../images/icons/emoji/basketball.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-church {
    background-image: url('../images/icons/emoji/church.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-lock_with_ink_pen {
    background-image: url('../images/icons/emoji/lock_with_ink_pen.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_mouth {
    background-image: url('../images/icons/emoji/no_mouth.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-notebook {
    background-image: url('../images/icons/emoji/notebook.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-exclamation {
    background-image: url('../images/icons/emoji/exclamation.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-orange_book {
    background-image: url('../images/icons/emoji/orange_book.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-convenience_store {
    background-image: url('../images/icons/emoji/convenience_store.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-stars {
    background-image: url('../images/icons/emoji/stars.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rice_ball {
    background-image: url('../images/icons/emoji/rice_ball.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cd {
    background-image: url('../images/icons/emoji/cd.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-flashlight {
    background-image: url('../images/icons/emoji/flashlight.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mailbox {
    background-image: url('../images/icons/emoji/mailbox.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-grin {
    background-image: url('../images/icons/emoji/grin.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-left_right_arrow {
    background-image: url('../images/icons/emoji/left_right_arrow.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-flushed {
    background-image: url('../images/icons/emoji/flushed.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-elephant {
    background-image: url('../images/icons/emoji/elephant.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-curly_loop {
    background-image: url('../images/icons/emoji/curly_loop.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-watch {
    background-image: url('../images/icons/emoji/watch.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-barber {
    background-image: url('../images/icons/emoji/barber.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-interrobang {
    background-image: url('../images/icons/emoji/interrobang.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-goberserk {
    background-image: url('../images/icons/emoji/goberserk.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fearful {
    background-image: url('../images/icons/emoji/fearful.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-stew {
    background-image: url('../images/icons/emoji/stew.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_right {
    background-image: url('../images/icons/emoji/arrow_right.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-potable_water {
    background-image: url('../images/icons/emoji/potable_water.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-green_book {
    background-image: url('../images/icons/emoji/green_book.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fishing_pole_and_fish {
    background-image: url('../images/icons/emoji/fishing_pole_and_fish.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-meat_on_bone {
    background-image: url('../images/icons/emoji/meat_on_bone.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heart_eyes {
    background-image: url('../images/icons/emoji/heart_eyes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-earth_asia {
    background-image: url('../images/icons/emoji/earth_asia.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bamboo {
    background-image: url('../images/icons/emoji/bamboo.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ant {
    background-image: url('../images/icons/emoji/ant.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-herb {
    background-image: url('../images/icons/emoji/herb.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-boot {
    background-image: url('../images/icons/emoji/boot.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sunglasses {
    background-image: url('../images/icons/emoji/sunglasses.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-abc {
    background-image: url('../images/icons/emoji/abc.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-waning_gibbous_moon {
    background-image: url('../images/icons/emoji/waning_gibbous_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cry {
    background-image: url('../images/icons/emoji/cry.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-joy {
    background-image: url('../images/icons/emoji/joy.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-open_mouth {
    background-image: url('../images/icons/emoji/open_mouth.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-new {
    background-image: url('../images/icons/emoji/new.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hibiscus {
    background-image: url('../images/icons/emoji/hibiscus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-triumph {
    background-image: url('../images/icons/emoji/triumph.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-es {
    background-image: url('../images/icons/emoji/es.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock10 {
    background-image: url('../images/icons/emoji/clock10.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-shell {
    background-image: url('../images/icons/emoji/shell.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-raised_hands {
    background-image: url('../images/icons/emoji/raised_hands.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-couple_with_heart {
    background-image: url('../images/icons/emoji/couple_with_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-snowflake {
    background-image: url('../images/icons/emoji/snowflake.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-izakaya_lantern {
    background-image: url('../images/icons/emoji/izakaya_lantern.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_medium_square {
    background-image: url('../images/icons/emoji/black_medium_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-poodle {
    background-image: url('../images/icons/emoji/poodle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hurtrealbad {
    background-image: url('../images/icons/emoji/hurtrealbad.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-steam_locomotive {
    background-image: url('../images/icons/emoji/steam_locomotive.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_bell {
    background-image: url('../images/icons/emoji/no_bell.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bikini {
    background-image: url('../images/icons/emoji/bikini.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-x {
    background-image: url('../images/icons/emoji/x.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-japanese_goblin {
    background-image: url('../images/icons/emoji/japanese_goblin.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-musical_keyboard {
    background-image: url('../images/icons/emoji/musical_keyboard.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bouquet {
    background-image: url('../images/icons/emoji/bouquet.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-high_brightness {
    background-image: url('../images/icons/emoji/high_brightness.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fire {
    background-image: url('../images/icons/emoji/fire.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-notebook_with_decorative_cover {
    background-image: url('../images/icons/emoji/notebook_with_decorative_cover.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-stuck_out_tongue_winking_eye {
    background-image: url('../images/icons/emoji/stuck_out_tongue_winking_eye.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-name_badge {
    background-image: url('../images/icons/emoji/name_badge.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bread {
    background-image: url('../images/icons/emoji/bread.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cake {
    background-image: url('../images/icons/emoji/cake.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heavy_exclamation_mark {
    background-image: url('../images/icons/emoji/heavy_exclamation_mark.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-video_camera {
    background-image: url('../images/icons/emoji/video_camera.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-syringe {
    background-image: url('../images/icons/emoji/syringe.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-swimmer {
    background-image: url('../images/icons/emoji/swimmer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fork_and_knife {
    background-image: url('../images/icons/emoji/fork_and_knife.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rage3 {
    background-image: url('../images/icons/emoji/rage3.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-snake {
    background-image: url('../images/icons/emoji/snake.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-banana {
    background-image: url('../images/icons/emoji/banana.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heart_decoration {
    background-image: url('../images/icons/emoji/heart_decoration.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-last_quarter_moon_with_face {
    background-image: url('../images/icons/emoji/last_quarter_moon_with_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dragon_face {
    background-image: url('../images/icons/emoji/dragon_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock2 {
    background-image: url('../images/icons/emoji/clock2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mag {
    background-image: url('../images/icons/emoji/mag.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-parking {
    background-image: url('../images/icons/emoji/parking.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-minibus {
    background-image: url('../images/icons/emoji/minibus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-frowning {
    background-image: url('../images/icons/emoji/frowning.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sailboat {
    background-image: url('../images/icons/emoji/sailboat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sound {
    background-image: url('../images/icons/emoji/sound.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-id {
    background-image: url('../images/icons/emoji/id.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-books {
    background-image: url('../images/icons/emoji/books.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-panda_face {
    background-image: url('../images/icons/emoji/panda_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-minidisc {
    background-image: url('../images/icons/emoji/minidisc.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fireworks {
    background-image: url('../images/icons/emoji/fireworks.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-spaghetti {
    background-image: url('../images/icons/emoji/spaghetti.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-headphones {
    background-image: url('../images/icons/emoji/headphones.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-part_alternation_mark {
    background-image: url('../images/icons/emoji/part_alternation_mark.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock930 {
    background-image: url('../images/icons/emoji/clock930.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-triangular_flag_on_post {
    background-image: url('../images/icons/emoji/triangular_flag_on_post.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-running {
    background-image: url('../images/icons/emoji/running.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-musical_score {
    background-image: url('../images/icons/emoji/musical_score.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ring {
    background-image: url('../images/icons/emoji/ring.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-eight_pointed_black_star {
    background-image: url('../images/icons/emoji/eight_pointed_black_star.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-santa {
    background-image: url('../images/icons/emoji/santa.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bus {
    background-image: url('../images/icons/emoji/bus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-joy_cat {
    background-image: url('../images/icons/emoji/joy_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wavy_dash {
    background-image: url('../images/icons/emoji/wavy_dash.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-metal {
    background-image: url('../images/icons/emoji/metal.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-zero {
    background-image: url('../images/icons/emoji/zero.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sunrise {
    background-image: url('../images/icons/emoji/sunrise.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-chart_with_downwards_trend {
    background-image: url('../images/icons/emoji/chart_with_downwards_trend.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-green_heart {
    background-image: url('../images/icons/emoji/green_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-briefcase {
    background-image: url('../images/icons/emoji/briefcase.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-beginner {
    background-image: url('../images/icons/emoji/beginner.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock830 {
    background-image: url('../images/icons/emoji/clock830.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-twisted_rightwards_arrows {
    background-image: url('../images/icons/emoji/twisted_rightwards_arrows.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-doughnut {
    background-image: url('../images/icons/emoji/doughnut.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-video_game {
    background-image: url('../images/icons/emoji/video_game.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-two {
    background-image: url('../images/icons/emoji/two.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-innocent {
    background-image: url('../images/icons/emoji/innocent.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-triangular_ruler {
    background-image: url('../images/icons/emoji/triangular_ruler.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-notes {
    background-image: url('../images/icons/emoji/notes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-koala {
    background-image: url('../images/icons/emoji/koala.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-person_with_blond_hair {
    background-image: url('../images/icons/emoji/person_with_blond_hair.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-signal_strength {
    background-image: url('../images/icons/emoji/signal_strength.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-crocodile {
    background-image: url('../images/icons/emoji/crocodile.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ballot_box_with_check {
    background-image: url('../images/icons/emoji/ballot_box_with_check.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cinema {
    background-image: url('../images/icons/emoji/cinema.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mens {
    background-image: url('../images/icons/emoji/mens.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-eggplant {
    background-image: url('../images/icons/emoji/eggplant.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-chart {
    background-image: url('../images/icons/emoji/chart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-slot_machine {
    background-image: url('../images/icons/emoji/slot_machine.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-toilet {
    background-image: url('../images/icons/emoji/toilet.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock630 {
    background-image: url('../images/icons/emoji/clock630.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-point_right {
    background-image: url('../images/icons/emoji/point_right.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clipboard {
    background-image: url('../images/icons/emoji/clipboard.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-aries {
    background-image: url('../images/icons/emoji/aries.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-birthday {
    background-image: url('../images/icons/emoji/birthday.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-busstop {
    background-image: url('../images/icons/emoji/busstop.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dog {
    background-image: url('../images/icons/emoji/dog.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_right_hook {
    background-image: url('../images/icons/emoji/arrow_right_hook.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-suspect {
    background-image: url('../images/icons/emoji/suspect.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hushed {
    background-image: url('../images/icons/emoji/hushed.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-leftwards_arrow_with_hook {
    background-image: url('../images/icons/emoji/leftwards_arrow_with_hook.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-suspension_railway {
    background-image: url('../images/icons/emoji/suspension_railway.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-crystal_ball {
    background-image: url('../images/icons/emoji/crystal_ball.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-peach {
    background-image: url('../images/icons/emoji/peach.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-point_up {
    background-image: url('../images/icons/emoji/point_up.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rage {
    background-image: url('../images/icons/emoji/rage.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-two_hearts {
    background-image: url('../images/icons/emoji/two_hearts.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-flags {
    background-image: url('../images/icons/emoji/flags.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hotel {
    background-image: url('../images/icons/emoji/hotel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-up {
    background-image: url('../images/icons/emoji/up.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dromedary_camel {
    background-image: url('../images/icons/emoji/dromedary_camel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pencil {
    background-image: url('../images/icons/emoji/pencil.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dancer {
    background-image: url('../images/icons/emoji/dancer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-loudspeaker {
    background-image: url('../images/icons/emoji/loudspeaker.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji--1 {
    background-image: url('../images/icons/emoji/-1.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rocket {
    background-image: url('../images/icons/emoji/rocket.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-performing_arts {
    background-image: url('../images/icons/emoji/performing_arts.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_heading_down {
    background-image: url('../images/icons/emoji/arrow_heading_down.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-capricorn {
    background-image: url('../images/icons/emoji/capricorn.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fire_engine {
    background-image: url('../images/icons/emoji/fire_engine.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-frog {
    background-image: url('../images/icons/emoji/frog.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rose {
    background-image: url('../images/icons/emoji/rose.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-high_heel {
    background-image: url('../images/icons/emoji/high_heel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-keycap_ten {
    background-image: url('../images/icons/emoji/keycap_ten.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-do_not_litter {
    background-image: url('../images/icons/emoji/do_not_litter.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_large_square {
    background-image: url('../images/icons/emoji/white_large_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sweet_potato {
    background-image: url('../images/icons/emoji/sweet_potato.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-anchor {
    background-image: url('../images/icons/emoji/anchor.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-leaves {
    background-image: url('../images/icons/emoji/leaves.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fr {
    background-image: url('../images/icons/emoji/fr.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-page_facing_up {
    background-image: url('../images/icons/emoji/page_facing_up.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-man_with_gua_pi_mao {
    background-image: url('../images/icons/emoji/man_with_gua_pi_mao.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-donut {
    background-image: url('../images/icons/emoji/donut.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-trollface {
    background-image: url('../images/icons/emoji/trollface.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ribbon {
    background-image: url('../images/icons/emoji/ribbon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wind_chime {
    background-image: url('../images/icons/emoji/wind_chime.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-older_woman {
    background-image: url('../images/icons/emoji/older_woman.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-alarm_clock {
    background-image: url('../images/icons/emoji/alarm_clock.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bird {
    background-image: url('../images/icons/emoji/bird.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock530 {
    background-image: url('../images/icons/emoji/clock530.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hearts {
    background-image: url('../images/icons/emoji/hearts.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-loop {
    background-image: url('../images/icons/emoji/loop.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-neutral_face {
    background-image: url('../images/icons/emoji/neutral_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-curry {
    background-image: url('../images/icons/emoji/curry.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pouch {
    background-image: url('../images/icons/emoji/pouch.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-outbox_tray {
    background-image: url('../images/icons/emoji/outbox_tray.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-balloon {
    background-image: url('../images/icons/emoji/balloon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hourglass_flowing_sand {
    background-image: url('../images/icons/emoji/hourglass_flowing_sand.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-worried {
    background-image: url('../images/icons/emoji/worried.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bridge_at_night {
    background-image: url('../images/icons/emoji/bridge_at_night.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_double_down {
    background-image: url('../images/icons/emoji/arrow_double_down.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-information_source {
    background-image: url('../images/icons/emoji/information_source.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_medium_small_square {
    background-image: url('../images/icons/emoji/white_medium_small_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-microscope {
    background-image: url('../images/icons/emoji/microscope.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heartpulse {
    background-image: url('../images/icons/emoji/heartpulse.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kissing {
    background-image: url('../images/icons/emoji/kissing.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cold_sweat {
    background-image: url('../images/icons/emoji/cold_sweat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-camel {
    background-image: url('../images/icons/emoji/camel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-lips {
    background-image: url('../images/icons/emoji/lips.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-family {
    background-image: url('../images/icons/emoji/family.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-four {
    background-image: url('../images/icons/emoji/four.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-abcd {
    background-image: url('../images/icons/emoji/abcd.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-circus_tent {
    background-image: url('../images/icons/emoji/circus_tent.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-restroom {
    background-image: url('../images/icons/emoji/restroom.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-copyright {
    background-image: url('../images/icons/emoji/copyright.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heavy_check_mark {
    background-image: url('../images/icons/emoji/heavy_check_mark.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sparkles {
    background-image: url('../images/icons/emoji/sparkles.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-telephone {
    background-image: url('../images/icons/emoji/telephone.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-snail {
    background-image: url('../images/icons/emoji/snail.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_mobile_phones {
    background-image: url('../images/icons/emoji/no_mobile_phones.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-boat {
    background-image: url('../images/icons/emoji/boat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-non-potable_water {
    background-image: url('../images/icons/emoji/non-potable_water.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-8ball {
    background-image: url('../images/icons/emoji/8ball.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wolf {
    background-image: url('../images/icons/emoji/wolf.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ok {
    background-image: url('../images/icons/emoji/ok.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tangerine {
    background-image: url('../images/icons/emoji/tangerine.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sparkling_heart {
    background-image: url('../images/icons/emoji/sparkling_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-city_sunrise {
    background-image: url('../images/icons/emoji/city_sunrise.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-light_rail {
    background-image: url('../images/icons/emoji/light_rail.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cl {
    background-image: url('../images/icons/emoji/cl.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cool {
    background-image: url('../images/icons/emoji/cool.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-haircut {
    background-image: url('../images/icons/emoji/haircut.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-spades {
    background-image: url('../images/icons/emoji/spades.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rotating_light {
    background-image: url('../images/icons/emoji/rotating_light.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smiling_imp {
    background-image: url('../images/icons/emoji/smiling_imp.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-shower {
    background-image: url('../images/icons/emoji/shower.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-taxi {
    background-image: url('../images/icons/emoji/taxi.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-factory {
    background-image: url('../images/icons/emoji/factory.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-accept {
    background-image: url('../images/icons/emoji/accept.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cat2 {
    background-image: url('../images/icons/emoji/cat2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-princess {
    background-image: url('../images/icons/emoji/princess.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-radio {
    background-image: url('../images/icons/emoji/radio.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-first_quarter_moon {
    background-image: url('../images/icons/emoji/first_quarter_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-punch {
    background-image: url('../images/icons/emoji/punch.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_circle {
    background-image: url('../images/icons/emoji/white_circle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tram {
    background-image: url('../images/icons/emoji/tram.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji---1 {
    background-image: url('../images/icons/emoji/+1.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-womans_hat {
    background-image: url('../images/icons/emoji/womans_hat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ramen {
    background-image: url('../images/icons/emoji/ramen.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sweat {
    background-image: url('../images/icons/emoji/sweat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heart {
    background-image: url('../images/icons/emoji/heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mushroom {
    background-image: url('../images/icons/emoji/mushroom.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ng {
    background-image: url('../images/icons/emoji/ng.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-weary {
    background-image: url('../images/icons/emoji/weary.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-star {
    background-image: url('../images/icons/emoji/star.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cherry_blossom {
    background-image: url('../images/icons/emoji/cherry_blossom.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sushi {
    background-image: url('../images/icons/emoji/sushi.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-man_with_turban {
    background-image: url('../images/icons/emoji/man_with_turban.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ab {
    background-image: url('../images/icons/emoji/ab.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fish_cake {
    background-image: url('../images/icons/emoji/fish_cake.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-shit {
    background-image: url('../images/icons/emoji/shit.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_down {
    background-image: url('../images/icons/emoji/arrow_down.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-boar {
    background-image: url('../images/icons/emoji/boar.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-one {
    background-image: url('../images/icons/emoji/one.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bomb {
    background-image: url('../images/icons/emoji/bomb.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-space_invader {
    background-image: url('../images/icons/emoji/space_invader.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-goat {
    background-image: url('../images/icons/emoji/goat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-file_folder {
    background-image: url('../images/icons/emoji/file_folder.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_medium_square {
    background-image: url('../images/icons/emoji/white_medium_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-package {
    background-image: url('../images/icons/emoji/package.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-lock {
    background-image: url('../images/icons/emoji/lock.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-us {
    background-image: url('../images/icons/emoji/us.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hocho {
    background-image: url('../images/icons/emoji/hocho.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dolls {
    background-image: url('../images/icons/emoji/dolls.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-on {
    background-image: url('../images/icons/emoji/on.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-checkered_flag {
    background-image: url('../images/icons/emoji/checkered_flag.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heart_eyes_cat {
    background-image: url('../images/icons/emoji/heart_eyes_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-see_no_evil {
    background-image: url('../images/icons/emoji/see_no_evil.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-five {
    background-image: url('../images/icons/emoji/five.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-it {
    background-image: url('../images/icons/emoji/it.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-crying_cat_face {
    background-image: url('../images/icons/emoji/crying_cat_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ox {
    background-image: url('../images/icons/emoji/ox.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-b {
    background-image: url('../images/icons/emoji/b.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mahjong {
    background-image: url('../images/icons/emoji/mahjong.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-camera {
    background-image: url('../images/icons/emoji/camera.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock7 {
    background-image: url('../images/icons/emoji/clock7.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_bicycles {
    background-image: url('../images/icons/emoji/no_bicycles.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-gemini {
    background-image: url('../images/icons/emoji/gemini.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-straight_ruler {
    background-image: url('../images/icons/emoji/straight_ruler.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rainbow {
    background-image: url('../images/icons/emoji/rainbow.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ru {
    background-image: url('../images/icons/emoji/ru.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-lipstick {
    background-image: url('../images/icons/emoji/lipstick.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-construction {
    background-image: url('../images/icons/emoji/construction.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-leo {
    background-image: url('../images/icons/emoji/leo.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-boy {
    background-image: url('../images/icons/emoji/boy.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-uk {
    background-image: url('../images/icons/emoji/uk.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clap {
    background-image: url('../images/icons/emoji/clap.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-zap {
    background-image: url('../images/icons/emoji/zap.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-baby {
    background-image: url('../images/icons/emoji/baby.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-traffic_light {
    background-image: url('../images/icons/emoji/traffic_light.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-email {
    background-image: url('../images/icons/emoji/email.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-deciduous_tree {
    background-image: url('../images/icons/emoji/deciduous_tree.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock130 {
    background-image: url('../images/icons/emoji/clock130.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dizzy {
    background-image: url('../images/icons/emoji/dizzy.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fish {
    background-image: url('../images/icons/emoji/fish.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sparkle {
    background-image: url('../images/icons/emoji/sparkle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pushpin {
    background-image: url('../images/icons/emoji/pushpin.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-red_circle {
    background-image: url('../images/icons/emoji/red_circle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-seedling {
    background-image: url('../images/icons/emoji/seedling.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-massage {
    background-image: url('../images/icons/emoji/massage.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-metro {
    background-image: url('../images/icons/emoji/metro.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-guitar {
    background-image: url('../images/icons/emoji/guitar.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock1230 {
    background-image: url('../images/icons/emoji/clock1230.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tiger2 {
    background-image: url('../images/icons/emoji/tiger2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wink {
    background-image: url('../images/icons/emoji/wink.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock1030 {
    background-image: url('../images/icons/emoji/clock1030.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-taurus {
    background-image: url('../images/icons/emoji/taurus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-blush {
    background-image: url('../images/icons/emoji/blush.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-a {
    background-image: url('../images/icons/emoji/a.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_flower {
    background-image: url('../images/icons/emoji/white_flower.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tropical_fish {
    background-image: url('../images/icons/emoji/tropical_fish.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sob {
    background-image: url('../images/icons/emoji/sob.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-japanese_ogre {
    background-image: url('../images/icons/emoji/japanese_ogre.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hash {
    background-image: url('../images/icons/emoji/hash.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-station {
    background-image: url('../images/icons/emoji/station.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-vhs {
    background-image: url('../images/icons/emoji/vhs.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-paw_prints {
    background-image: url('../images/icons/emoji/paw_prints.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-expressionless {
    background-image: url('../images/icons/emoji/expressionless.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-lollipop {
    background-image: url('../images/icons/emoji/lollipop.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hand {
    background-image: url('../images/icons/emoji/hand.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-e-mail {
    background-image: url('../images/icons/emoji/e-mail.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sunny {
    background-image: url('../images/icons/emoji/sunny.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-shirt {
    background-image: url('../images/icons/emoji/shirt.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-de {
    background-image: url('../images/icons/emoji/de.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-symbols {
    background-image: url('../images/icons/emoji/symbols.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kissing_closed_eyes {
    background-image: url('../images/icons/emoji/kissing_closed_eyes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-underage {
    background-image: url('../images/icons/emoji/underage.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cyclone {
    background-image: url('../images/icons/emoji/cyclone.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dragon {
    background-image: url('../images/icons/emoji/dragon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-candy {
    background-image: url('../images/icons/emoji/candy.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ledger {
    background-image: url('../images/icons/emoji/ledger.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-raising_hand {
    background-image: url('../images/icons/emoji/raising_hand.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-busts_in_silhouette {
    background-image: url('../images/icons/emoji/busts_in_silhouette.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rat {
    background-image: url('../images/icons/emoji/rat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-grey_exclamation {
    background-image: url('../images/icons/emoji/grey_exclamation.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u5408 {
    background-image: url('../images/icons/emoji/u5408.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-information_desk_person {
    background-image: url('../images/icons/emoji/information_desk_person.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sun_with_face {
    background-image: url('../images/icons/emoji/sun_with_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dollar {
    background-image: url('../images/icons/emoji/dollar.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smoking {
    background-image: url('../images/icons/emoji/smoking.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-iphone {
    background-image: url('../images/icons/emoji/iphone.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wc {
    background-image: url('../images/icons/emoji/wc.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-police_car {
    background-image: url('../images/icons/emoji/police_car.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_down_small {
    background-image: url('../images/icons/emoji/arrow_down_small.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-koko {
    background-image: url('../images/icons/emoji/koko.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kissing_smiling_eyes {
    background-image: url('../images/icons/emoji/kissing_smiling_eyes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-oncoming_police_car {
    background-image: url('../images/icons/emoji/oncoming_police_car.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kissing_heart {
    background-image: url('../images/icons/emoji/kissing_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-crossed_flags {
    background-image: url('../images/icons/emoji/crossed_flags.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-boom {
    background-image: url('../images/icons/emoji/boom.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-earth_africa {
    background-image: url('../images/icons/emoji/earth_africa.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smile {
    background-image: url('../images/icons/emoji/smile.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-simple_smile {
    background-image: url('../images/icons/emoji/simple_smile.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-art {
    background-image: url('../images/icons/emoji/art.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-red_car {
    background-image: url('../images/icons/emoji/red_car.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-satellite {
    background-image: url('../images/icons/emoji/satellite.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-credit_card {
    background-image: url('../images/icons/emoji/credit_card.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-card_index {
    background-image: url('../images/icons/emoji/card_index.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ticket {
    background-image: url('../images/icons/emoji/ticket.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-womans_clothes {
    background-image: url('../images/icons/emoji/womans_clothes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hamburger {
    background-image: url('../images/icons/emoji/hamburger.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fax {
    background-image: url('../images/icons/emoji/fax.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mailbox_with_no_mail {
    background-image: url('../images/icons/emoji/mailbox_with_no_mail.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_pedestrians {
    background-image: url('../images/icons/emoji/no_pedestrians.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock230 {
    background-image: url('../images/icons/emoji/clock230.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-feet {
    background-image: url('../images/icons/emoji/feet.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wedding {
    background-image: url('../images/icons/emoji/wedding.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-saxophone {
    background-image: url('../images/icons/emoji/saxophone.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-battery {
    background-image: url('../images/icons/emoji/battery.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-beers {
    background-image: url('../images/icons/emoji/beers.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ideograph_advantage {
    background-image: url('../images/icons/emoji/ideograph_advantage.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-shaved_ice {
    background-image: url('../images/icons/emoji/shaved_ice.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-seat {
    background-image: url('../images/icons/emoji/seat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-white_square_button {
    background-image: url('../images/icons/emoji/white_square_button.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-angel {
    background-image: url('../images/icons/emoji/angel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-unamused {
    background-image: url('../images/icons/emoji/unamused.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rowboat {
    background-image: url('../images/icons/emoji/rowboat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock6 {
    background-image: url('../images/icons/emoji/clock6.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_square_button {
    background-image: url('../images/icons/emoji/black_square_button.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_up_small {
    background-image: url('../images/icons/emoji/arrow_up_small.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_up {
    background-image: url('../images/icons/emoji/arrow_up.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rice_scene {
    background-image: url('../images/icons/emoji/rice_scene.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-scorpius {
    background-image: url('../images/icons/emoji/scorpius.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-incoming_envelope {
    background-image: url('../images/icons/emoji/incoming_envelope.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tent {
    background-image: url('../images/icons/emoji/tent.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-computer {
    background-image: url('../images/icons/emoji/computer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-poultry_leg {
    background-image: url('../images/icons/emoji/poultry_leg.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ocean {
    background-image: url('../images/icons/emoji/ocean.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-currency_exchange {
    background-image: url('../images/icons/emoji/currency_exchange.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-recycle {
    background-image: url('../images/icons/emoji/recycle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-confounded {
    background-image: url('../images/icons/emoji/confounded.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock1130 {
    background-image: url('../images/icons/emoji/clock1130.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-love_hotel {
    background-image: url('../images/icons/emoji/love_hotel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dress {
    background-image: url('../images/icons/emoji/dress.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-open_hands {
    background-image: url('../images/icons/emoji/open_hands.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-disappointed {
    background-image: url('../images/icons/emoji/disappointed.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-relaxed {
    background-image: url('../images/icons/emoji/relaxed.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dog2 {
    background-image: url('../images/icons/emoji/dog2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-speaker {
    background-image: url('../images/icons/emoji/speaker.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ok_hand {
    background-image: url('../images/icons/emoji/ok_hand.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-feelsgood {
    background-image: url('../images/icons/emoji/feelsgood.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-three {
    background-image: url('../images/icons/emoji/three.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-strawberry {
    background-image: url('../images/icons/emoji/strawberry.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-game_die {
    background-image: url('../images/icons/emoji/game_die.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-couplekiss {
    background-image: url('../images/icons/emoji/couplekiss.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-surfer {
    background-image: url('../images/icons/emoji/surfer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cat {
    background-image: url('../images/icons/emoji/cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-trumpet {
    background-image: url('../images/icons/emoji/trumpet.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-man {
    background-image: url('../images/icons/emoji/man.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pager {
    background-image: url('../images/icons/emoji/pager.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tiger {
    background-image: url('../images/icons/emoji/tiger.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-racehorse {
    background-image: url('../images/icons/emoji/racehorse.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-coffee {
    background-image: url('../images/icons/emoji/coffee.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-confused {
    background-image: url('../images/icons/emoji/confused.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bug {
    background-image: url('../images/icons/emoji/bug.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fu {
    background-image: url('../images/icons/emoji/fu.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fast_forward {
    background-image: url('../images/icons/emoji/fast_forward.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-apple {
    background-image: url('../images/icons/emoji/apple.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-page_with_curl {
    background-image: url('../images/icons/emoji/page_with_curl.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ear_of_rice {
    background-image: url('../images/icons/emoji/ear_of_rice.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-chicken {
    background-image: url('../images/icons/emoji/chicken.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-nail_care {
    background-image: url('../images/icons/emoji/nail_care.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ship {
    background-image: url('../images/icons/emoji/ship.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-passport_control {
    background-image: url('../images/icons/emoji/passport_control.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-watermelon {
    background-image: url('../images/icons/emoji/watermelon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rabbit2 {
    background-image: url('../images/icons/emoji/rabbit2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-four_leaf_clover {
    background-image: url('../images/icons/emoji/four_leaf_clover.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-eyes {
    background-image: url('../images/icons/emoji/eyes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tophat {
    background-image: url('../images/icons/emoji/tophat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-repeat {
    background-image: url('../images/icons/emoji/repeat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-person_frowning {
    background-image: url('../images/icons/emoji/person_frowning.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-link {
    background-image: url('../images/icons/emoji/link.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mountain_bicyclist {
    background-image: url('../images/icons/emoji/mountain_bicyclist.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dango {
    background-image: url('../images/icons/emoji/dango.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-jack_o_lantern {
    background-image: url('../images/icons/emoji/jack_o_lantern.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-two_women_holding_hands {
    background-image: url('../images/icons/emoji/two_women_holding_hands.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-office {
    background-image: url('../images/icons/emoji/office.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-blue_book {
    background-image: url('../images/icons/emoji/blue_book.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-blue_heart {
    background-image: url('../images/icons/emoji/blue_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cupid {
    background-image: url('../images/icons/emoji/cupid.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mouse {
    background-image: url('../images/icons/emoji/mouse.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-crown {
    background-image: url('../images/icons/emoji/crown.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-runner {
    background-image: url('../images/icons/emoji/runner.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bookmark_tabs {
    background-image: url('../images/icons/emoji/bookmark_tabs.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-blue_car {
    background-image: url('../images/icons/emoji/blue_car.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smirk_cat {
    background-image: url('../images/icons/emoji/smirk_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-registered {
    background-image: url('../images/icons/emoji/registered.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-blossom {
    background-image: url('../images/icons/emoji/blossom.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-airplane {
    background-image: url('../images/icons/emoji/airplane.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sleeping {
    background-image: url('../images/icons/emoji/sleeping.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-shoe {
    background-image: url('../images/icons/emoji/shoe.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tongue {
    background-image: url('../images/icons/emoji/tongue.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-monkey {
    background-image: url('../images/icons/emoji/monkey.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-negative_squared_cross_mark {
    background-image: url('../images/icons/emoji/negative_squared_cross_mark.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock8 {
    background-image: url('../images/icons/emoji/clock8.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-eight_spoked_asterisk {
    background-image: url('../images/icons/emoji/eight_spoked_asterisk.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pound {
    background-image: url('../images/icons/emoji/pound.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bath {
    background-image: url('../images/icons/emoji/bath.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bow {
    background-image: url('../images/icons/emoji/bow.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrows_counterclockwise {
    background-image: url('../images/icons/emoji/arrows_counterclockwise.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ophiuchus {
    background-image: url('../images/icons/emoji/ophiuchus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bowtie {
    background-image: url('../images/icons/emoji/bowtie.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-alien {
    background-image: url('../images/icons/emoji/alien.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bullettrain_front {
    background-image: url('../images/icons/emoji/bullettrain_front.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock4 {
    background-image: url('../images/icons/emoji/clock4.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-custard {
    background-image: url('../images/icons/emoji/custard.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-corn {
    background-image: url('../images/icons/emoji/corn.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-school_satchel {
    background-image: url('../images/icons/emoji/school_satchel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-honeybee {
    background-image: url('../images/icons/emoji/honeybee.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock3 {
    background-image: url('../images/icons/emoji/clock3.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kr {
    background-image: url('../images/icons/emoji/kr.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tennis {
    background-image: url('../images/icons/emoji/tennis.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u55b6 {
    background-image: url('../images/icons/emoji/u55b6.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-children_crossing {
    background-image: url('../images/icons/emoji/children_crossing.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-newspaper {
    background-image: url('../images/icons/emoji/newspaper.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-open_file_folder {
    background-image: url('../images/icons/emoji/open_file_folder.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-satisfied {
    background-image: url('../images/icons/emoji/satisfied.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-baseball {
    background-image: url('../images/icons/emoji/baseball.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bathtub {
    background-image: url('../images/icons/emoji/bathtub.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-new_moon {
    background-image: url('../images/icons/emoji/new_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-shipit {
    background-image: url('../images/icons/emoji/shipit.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-waxing_crescent_moon {
    background-image: url('../images/icons/emoji/waxing_crescent_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dart {
    background-image: url('../images/icons/emoji/dart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-star2 {
    background-image: url('../images/icons/emoji/star2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-memo {
    background-image: url('../images/icons/emoji/memo.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-waxing_gibbous_moon {
    background-image: url('../images/icons/emoji/waxing_gibbous_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-six_pointed_star {
    background-image: url('../images/icons/emoji/six_pointed_star.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock330 {
    background-image: url('../images/icons/emoji/clock330.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-astonished {
    background-image: url('../images/icons/emoji/astonished.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_upper_left {
    background-image: url('../images/icons/emoji/arrow_upper_left.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-post_office {
    background-image: url('../images/icons/emoji/post_office.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u5272 {
    background-image: url('../images/icons/emoji/u5272.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u6e80 {
    background-image: url('../images/icons/emoji/u6e80.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u7121 {
    background-image: url('../images/icons/emoji/u7121.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cookie {
    background-image: url('../images/icons/emoji/cookie.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-yen {
    background-image: url('../images/icons/emoji/yen.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-scissors {
    background-image: url('../images/icons/emoji/scissors.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cactus {
    background-image: url('../images/icons/emoji/cactus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sagittarius {
    background-image: url('../images/icons/emoji/sagittarius.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-point_up_2 {
    background-image: url('../images/icons/emoji/point_up_2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-jeans {
    background-image: url('../images/icons/emoji/jeans.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heavy_dollar_sign {
    background-image: url('../images/icons/emoji/heavy_dollar_sign.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-neckbeard {
    background-image: url('../images/icons/emoji/neckbeard.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heavy_plus_sign {
    background-image: url('../images/icons/emoji/heavy_plus_sign.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ok_woman {
    background-image: url('../images/icons/emoji/ok_woman.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rice {
    background-image: url('../images/icons/emoji/rice.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smiley {
    background-image: url('../images/icons/emoji/smiley.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wrench {
    background-image: url('../images/icons/emoji/wrench.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tired_face {
    background-image: url('../images/icons/emoji/tired_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-collision {
    background-image: url('../images/icons/emoji/collision.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bell {
    background-image: url('../images/icons/emoji/bell.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-relieved {
    background-image: url('../images/icons/emoji/relieved.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clapper {
    background-image: url('../images/icons/emoji/clapper.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mountain_railway {
    background-image: url('../images/icons/emoji/mountain_railway.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cow {
    background-image: url('../images/icons/emoji/cow.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u6708 {
    background-image: url('../images/icons/emoji/u6708.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u7533 {
    background-image: url('../images/icons/emoji/u7533.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mouse2 {
    background-image: url('../images/icons/emoji/mouse2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heavy_multiplication_x {
    background-image: url('../images/icons/emoji/heavy_multiplication_x.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_forward {
    background-image: url('../images/icons/emoji/arrow_forward.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smirk {
    background-image: url('../images/icons/emoji/smirk.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_small_square {
    background-image: url('../images/icons/emoji/black_small_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_backward {
    background-image: url('../images/icons/emoji/arrow_backward.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sa {
    background-image: url('../images/icons/emoji/sa.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-helicopter {
    background-image: url('../images/icons/emoji/helicopter.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_entry {
    background-image: url('../images/icons/emoji/no_entry.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-grapes {
    background-image: url('../images/icons/emoji/grapes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-confetti_ball {
    background-image: url('../images/icons/emoji/confetti_ball.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-older_man {
    background-image: url('../images/icons/emoji/older_man.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-soon {
    background-image: url('../images/icons/emoji/soon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pineapple {
    background-image: url('../images/icons/emoji/pineapple.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-smiley_cat {
    background-image: url('../images/icons/emoji/smiley_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sparkler {
    background-image: url('../images/icons/emoji/sparkler.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-nut_and_bolt {
    background-image: url('../images/icons/emoji/nut_and_bolt.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-facepunch {
    background-image: url('../images/icons/emoji/facepunch.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pizza {
    background-image: url('../images/icons/emoji/pizza.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bento {
    background-image: url('../images/icons/emoji/bento.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-leopard {
    background-image: url('../images/icons/emoji/leopard.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sweat_smile {
    background-image: url('../images/icons/emoji/sweat_smile.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-baby_chick {
    background-image: url('../images/icons/emoji/baby_chick.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-european_castle {
    background-image: url('../images/icons/emoji/european_castle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-o2 {
    background-image: url('../images/icons/emoji/o2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-speak_no_evil {
    background-image: url('../images/icons/emoji/speak_no_evil.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bike {
    background-image: url('../images/icons/emoji/bike.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bullettrain_side {
    background-image: url('../images/icons/emoji/bullettrain_side.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-stuck_out_tongue_closed_eyes {
    background-image: url('../images/icons/emoji/stuck_out_tongue_closed_eyes.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-last_quarter_moon {
    background-image: url('../images/icons/emoji/last_quarter_moon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-thumbsdown {
    background-image: url('../images/icons/emoji/thumbsdown.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-libra {
    background-image: url('../images/icons/emoji/libra.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tropical_drink {
    background-image: url('../images/icons/emoji/tropical_drink.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-top {
    background-image: url('../images/icons/emoji/top.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-large_blue_circle {
    background-image: url('../images/icons/emoji/large_blue_circle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-radio_button {
    background-image: url('../images/icons/emoji/radio_button.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-chart_with_upwards_trend {
    background-image: url('../images/icons/emoji/chart_with_upwards_trend.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-no_smoking {
    background-image: url('../images/icons/emoji/no_smoking.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-oncoming_automobile {
    background-image: url('../images/icons/emoji/oncoming_automobile.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-japanese_castle {
    background-image: url('../images/icons/emoji/japanese_castle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-yellow_heart {
    background-image: url('../images/icons/emoji/yellow_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-broken_heart {
    background-image: url('../images/icons/emoji/broken_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-oncoming_bus {
    background-image: url('../images/icons/emoji/oncoming_bus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-blowfish {
    background-image: url('../images/icons/emoji/blowfish.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-maple_leaf {
    background-image: url('../images/icons/emoji/maple_leaf.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wheelchair {
    background-image: url('../images/icons/emoji/wheelchair.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-godmode {
    background-image: url('../images/icons/emoji/godmode.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cow2 {
    background-image: url('../images/icons/emoji/cow2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mailbox_closed {
    background-image: url('../images/icons/emoji/mailbox_closed.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-musical_note {
    background-image: url('../images/icons/emoji/musical_note.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-telescope {
    background-image: url('../images/icons/emoji/telescope.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-oncoming_taxi {
    background-image: url('../images/icons/emoji/oncoming_taxi.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-turtle {
    background-image: url('../images/icons/emoji/turtle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fuelpump {
    background-image: url('../images/icons/emoji/fuelpump.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock430 {
    background-image: url('../images/icons/emoji/clock430.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hatching_chick {
    background-image: url('../images/icons/emoji/hatching_chick.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-closed_lock_with_key {
    background-image: url('../images/icons/emoji/closed_lock_with_key.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-m {
    background-image: url('../images/icons/emoji/m.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ambulance {
    background-image: url('../images/icons/emoji/ambulance.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pouting_cat {
    background-image: url('../images/icons/emoji/pouting_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock11 {
    background-image: url('../images/icons/emoji/clock11.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-new_moon_with_face {
    background-image: url('../images/icons/emoji/new_moon_with_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-violin {
    background-image: url('../images/icons/emoji/violin.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-free {
    background-image: url('../images/icons/emoji/free.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-octocat {
    background-image: url('../images/icons/emoji/octocat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-volcano {
    background-image: url('../images/icons/emoji/volcano.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tokyo_tower {
    background-image: url('../images/icons/emoji/tokyo_tower.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hammer {
    background-image: url('../images/icons/emoji/hammer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-aerial_tramway {
    background-image: url('../images/icons/emoji/aerial_tramway.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hear_no_evil {
    background-image: url('../images/icons/emoji/hear_no_evil.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-palm_tree {
    background-image: url('../images/icons/emoji/palm_tree.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-small_red_triangle {
    background-image: url('../images/icons/emoji/small_red_triangle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u6307 {
    background-image: url('../images/icons/emoji/u6307.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-end {
    background-image: url('../images/icons/emoji/end.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-grinning {
    background-image: url('../images/icons/emoji/grinning.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pig {
    background-image: url('../images/icons/emoji/pig.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-revolving_hearts {
    background-image: url('../images/icons/emoji/revolving_hearts.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-honey_pot {
    background-image: url('../images/icons/emoji/honey_pot.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-gb {
    background-image: url('../images/icons/emoji/gb.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-point_left {
    background-image: url('../images/icons/emoji/point_left.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-vs {
    background-image: url('../images/icons/emoji/vs.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hospital {
    background-image: url('../images/icons/emoji/hospital.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pig2 {
    background-image: url('../images/icons/emoji/pig2.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-v {
    background-image: url('../images/icons/emoji/v.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-money_with_wings {
    background-image: url('../images/icons/emoji/money_with_wings.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mask {
    background-image: url('../images/icons/emoji/mask.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rugby_football {
    background-image: url('../images/icons/emoji/rugby_football.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-department_store {
    background-image: url('../images/icons/emoji/department_store.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pisces {
    background-image: url('../images/icons/emoji/pisces.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-football {
    background-image: url('../images/icons/emoji/football.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-wave {
    background-image: url('../images/icons/emoji/wave.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-soccer {
    background-image: url('../images/icons/emoji/soccer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-eight {
    background-image: url('../images/icons/emoji/eight.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-scream {
    background-image: url('../images/icons/emoji/scream.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-angry {
    background-image: url('../images/icons/emoji/angry.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-car {
    background-image: url('../images/icons/emoji/car.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-postal_horn {
    background-image: url('../images/icons/emoji/postal_horn.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ear {
    background-image: url('../images/icons/emoji/ear.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-globe_with_meridians {
    background-image: url('../images/icons/emoji/globe_with_meridians.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bear {
    background-image: url('../images/icons/emoji/bear.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hamster {
    background-image: url('../images/icons/emoji/hamster.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sleepy {
    background-image: url('../images/icons/emoji/sleepy.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kissing_face {
    background-image: url('../images/icons/emoji/kissing_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-speech_balloon {
    background-image: url('../images/icons/emoji/speech_balloon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sandal {
    background-image: url('../images/icons/emoji/sandal.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-bulb {
    background-image: url('../images/icons/emoji/bulb.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-floppy_disk {
    background-image: url('../images/icons/emoji/floppy_disk.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-u6709 {
    background-image: url('../images/icons/emoji/u6709.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-muscle {
    background-image: url('../images/icons/emoji/muscle.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-gift {
    background-image: url('../images/icons/emoji/gift.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hourglass {
    background-image: url('../images/icons/emoji/hourglass.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-couple {
    background-image: url('../images/icons/emoji/couple.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-closed_umbrella {
    background-image: url('../images/icons/emoji/closed_umbrella.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-grimacing {
    background-image: url('../images/icons/emoji/grimacing.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-jp {
    background-image: url('../images/icons/emoji/jp.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cop {
    background-image: url('../images/icons/emoji/cop.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tada {
    background-image: url('../images/icons/emoji/tada.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-milky_way {
    background-image: url('../images/icons/emoji/milky_way.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-gem {
    background-image: url('../images/icons/emoji/gem.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-clock9 {
    background-image: url('../images/icons/emoji/clock9.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-horse {
    background-image: url('../images/icons/emoji/horse.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-evergreen_tree {
    background-image: url('../images/icons/emoji/evergreen_tree.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-cocktail {
    background-image: url('../images/icons/emoji/cocktail.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-inbox_tray {
    background-image: url('../images/icons/emoji/inbox_tray.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-trolleybus {
    background-image: url('../images/icons/emoji/trolleybus.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-heavy_minus_sign {
    background-image: url('../images/icons/emoji/heavy_minus_sign.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-running_shirt_with_sash {
    background-image: url('../images/icons/emoji/running_shirt_with_sash.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-womens {
    background-image: url('../images/icons/emoji/womens.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-point_down {
    background-image: url('../images/icons/emoji/point_down.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-kiss {
    background-image: url('../images/icons/emoji/kiss.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dancers {
    background-image: url('../images/icons/emoji/dancers.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-two_men_holding_hands {
    background-image: url('../images/icons/emoji/two_men_holding_hands.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-congratulations {
    background-image: url('../images/icons/emoji/congratulations.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-green_apple {
    background-image: url('../images/icons/emoji/green_apple.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-unlock {
    background-image: url('../images/icons/emoji/unlock.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-egg {
    background-image: url('../images/icons/emoji/egg.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-door {
    background-image: url('../images/icons/emoji/door.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rice_cracker {
    background-image: url('../images/icons/emoji/rice_cracker.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-fallen_leaf {
    background-image: url('../images/icons/emoji/fallen_leaf.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-book {
    background-image: url('../images/icons/emoji/book.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-lemon {
    background-image: url('../images/icons/emoji/lemon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-poop {
    background-image: url('../images/icons/emoji/poop.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-beer {
    background-image: url('../images/icons/emoji/beer.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tractor {
    background-image: url('../images/icons/emoji/tractor.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-statue_of_liberty {
    background-image: url('../images/icons/emoji/statue_of_liberty.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-low_brightness {
    background-image: url('../images/icons/emoji/low_brightness.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sake {
    background-image: url('../images/icons/emoji/sake.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-snowman {
    background-image: url('../images/icons/emoji/snowman.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-nine {
    background-image: url('../images/icons/emoji/nine.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-microphone {
    background-image: url('../images/icons/emoji/microphone.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-aquarius {
    background-image: url('../images/icons/emoji/aquarius.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pig_nose {
    background-image: url('../images/icons/emoji/pig_nose.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-ferris_wheel {
    background-image: url('../images/icons/emoji/ferris_wheel.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-full_moon_with_face {
    background-image: url('../images/icons/emoji/full_moon_with_face.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-water_buffalo {
    background-image: url('../images/icons/emoji/water_buffalo.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_medium_small_square {
    background-image: url('../images/icons/emoji/black_medium_small_square.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-purple_heart {
    background-image: url('../images/icons/emoji/purple_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-mega {
    background-image: url('../images/icons/emoji/mega.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-christmas_tree {
    background-image: url('../images/icons/emoji/christmas_tree.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-back {
    background-image: url('../images/icons/emoji/back.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-rabbit {
    background-image: url('../images/icons/emoji/rabbit.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-gift_heart {
    background-image: url('../images/icons/emoji/gift_heart.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sweat_drops {
    background-image: url('../images/icons/emoji/sweat_drops.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-black_nib {
    background-image: url('../images/icons/emoji/black_nib.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_heading_up {
    background-image: url('../images/icons/emoji/arrow_heading_up.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-arrow_upper_right {
    background-image: url('../images/icons/emoji/arrow_upper_right.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-earth_americas {
    background-image: url('../images/icons/emoji/earth_americas.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-o {
    background-image: url('../images/icons/emoji/o.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-closed_book {
    background-image: url('../images/icons/emoji/closed_book.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-speedboat {
    background-image: url('../images/icons/emoji/speedboat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-sheep {
    background-image: url('../images/icons/emoji/sheep.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-customs {
    background-image: url('../images/icons/emoji/customs.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-hatched_chick {
    background-image: url('../images/icons/emoji/hatched_chick.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-scream_cat {
    background-image: url('../images/icons/emoji/scream_cat.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-thought_balloon {
    background-image: url('../images/icons/emoji/thought_balloon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-tanabata_tree {
    background-image: url('../images/icons/emoji/tanabata_tree.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-dolphin {
    background-image: url('../images/icons/emoji/dolphin.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-pear {
    background-image: url('../images/icons/emoji/pear.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-melon {
    background-image: url('../images/icons/emoji/melon.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-anguished {
    background-image: url('../images/icons/emoji/anguished.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-house_with_garden {
    background-image: url('../images/icons/emoji/house_with_garden.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-small_blue_diamond {
    background-image: url('../images/icons/emoji/small_blue_diamond.png');
    color: rgba(0, 0, 0, 0) !important;
}

.emoji-purse {
    background-image: url('../images/icons/emoji/purse.png');
    color: rgba(0, 0, 0, 0) !important;
}
