@staticBaseUrl: "https://cdn.beatsense.com/public";
#update-invalid-email-modal{
  &.my-modal{
    width: 100%;
    display: table;
    margin-bottom: 15px;
    .modal-header{
      padding-bottom: 3px;
      h2{
        font-weight: bold;
        text-align: center;
      }
    }
    label{
      margin-bottom: 5px;
    }
    button{
      font-weight: bold;
    }
    input{
      float: left;
      width: 70%;
      margin-right: 10px;
    }
  }
}