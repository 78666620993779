.searchBoxHolder {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #e8e8e8;
  padding: 3px 10px;
  width: 100%;
  font-weight: normal;
}
.searchBoxHolder input {
  border: none;
  height: 20px;
  flex-grow: 2;
  max-width: 188px;
}
.searchBoxHolder .fa-search {
  position: relative;
  z-index: 1;
  color: gray;
}
.searchBoxHolder i {
  position: relative;
  z-index: 1;
  color: #eaeaea;
}
.searchBoxHolder i.withText {
  color: #6f6f6f;
}
.searchBoxHolder i.disabled {
  color: #eaeaea;
  cursor: default;
}
