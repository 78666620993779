.sticky-song-modal .modal-content {
  min-width: 500px !important;
}
.sticky-song-modal .user-link {
  color: blue;
}
.sticky-song-modal .fa-compact-disc {
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.sticky-song-modal h3 {
  font-weight: bold;
  padding: 10px;
}
.sticky-song-modal button {
  background-color: rgba(33, 150, 243, 0.8);
  border: none;
  position: absolute;
  top: 55px;
  right: 20px;
  padding: 5px 7px 6px 10px;
  border-radius: 0x;
}
.sticky-song-modal .player-holder {
  margin-top: 20px;
}
