.blinkOrangeBackground {
  -webkit-animation: blinkBackground 0.3s 4;
  animation: blinkBackground 0.3s 4;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@-webkit-keyframes blinkBackground {
  from {
    background-color: rgba(244, 103, 72, 0);
  }
  to {
    background-color: rgba(244, 103, 72, 0.7);
  }
}
@keyframes blinkBackground {
  from {
    background-color: rgba(244, 103, 72, 0);
  }
  to {
    background-color: rgba(244, 103, 72, 0.7);
  }
}
.blinkOrangeBackground .blinkBackgroundFunc from {
  background-color: rgba(244, 103, 72, 0);
}
.blinkOrangeBackground .blinkBackgroundFunc to {
  background-color: rgba(244, 103, 72, 0.7);
}
.noblink {
  animation-iteration-count: 0 !important;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.9, 0.9);
  }
  8% {
    -webkit-transform: scale(1.05, 1.05);
  }
  20% {
    -webkit-transform: scale(0.9, 0.9);
  }
  28% {
    -webkit-transform: scale(1.05, 1.05);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9, 0.9);
  }
  6% {
    transform: scale(1.05, 1.05);
  }
  12% {
    transform: scale(0.9, 0.9);
  }
  18% {
    transform: scale(1.05, 1.05);
  }
  24% {
    transform: scale(0.9, 0.9);
  }
}
.glow-on-hover {
  position: relative;
  z-index: 0;
}
.glow-on-hover:hover {
  color: white !important;
}
.glow-on-hover.static:before {
  opacity: 1;
}
.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: 400%;
  z-index: -1;
  width: calc(100%);
  height: calc(100%);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.glow-on-hover:active:after {
  background: transparent;
}
.glow-on-hover:hover:before {
  opacity: 1;
}
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
