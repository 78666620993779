@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";

#add-song-to-beat-box-menu-holder {
  position: absolute;
  top: 100px;
  left: 500px;
  background-color: #fff;
  padding: 6px 10px 10px;
  border: @border;
  z-index: 1051;
  .smallText;
  display: none;

  .btnClose {
    color: #666666;
    position: absolute;
    right: 5px;
    top: 3px;
    cursor: pointer;
  }

  input {
    border: 1px solid @color0;
    padding-left: 4px;
    margin-top: 6px;
    position: relative;
    top: 2px;
  }
  .message {
    color: @green;
    margin-top: 5px;
    margin-right: 5px;
  }
  .cases {
    max-height: 200px;
    a {
      max-width: 200px;
      margin-bottom: 3px;
    }

    .case{
      &.scrolled{
        background-color: #efefef;
      }
    }
  }
  .publishChkbx{
    border-bottom: 1px solid lightgray;
    padding-bottom: 7px;
  }
  .newCase {
    input{
      width: 100%;
    }
    button {
      position: relative;
      top: 4px;
      padding: 3px 7px 4px;
    }
  }
  .title {
    font-weight: 200;
    margin: 5px 0px;
  }
  .browse {
    margin: 3px 0 3px;
    a {
      text-decoration: underline;
      position: relative;
      top: 4px;
      .smallerText;
      font-weight: bold;
      color: @color0_dark;
    }
  }
}
